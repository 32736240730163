import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import css from "./index.module.scss";
import classnames from "classnames";

import AuthCheck from "../../../components/authcheck/authCheck";
import authState from "../../../components/authcheck/authState";
import Layout from "../../../layout";
import Table from "../components/action-table";
import useSelectApi from "../../../hooks/useSelectApi";
import { IOffice } from "../../../types/select-api/ResponseTypes";
import Loading from "../../../components/loading";
import Button from "../../../components/button/button";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../components/breadcrumbs";

function Offices() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [offices, setOffices] = useState<Array<IOffice>>([]);
  const { getOffices } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: false,
  });

  const breadcrumbs = [
    { name: "Home", path: "/" },
    { name: "Offices", isActive: true },
  ];

  const handleAttributesClick = (officeId: string, officeName: string) => {
    history.push(`/manage-office-attributes/${officeId}`);
  };

  const handlePricingClick = (officeId: string, officeName: string) => {
    history.push(`/manage-office-pricing/${officeId}`);
  };

  const renderCell = (row: IOffice, header: string) => {
    switch (header) {
      case "OFFICE NAME":
        return row.name;
      default:
        return "";
    }
  };

  const renderActions = (row: IOffice) => (
    <div>
      <Button
        id=""
        datatestid=""
        btnClass={classnames(css.actionButton)}
        onClick={() => handlePricingClick(row.id, row.name)}
        type="button"
        text="EDIT PRICING"
      />
      <Button
        id=""
        datatestid=""
        btnClass={classnames(css.actionButton)}
        onClick={() => handleAttributesClick(row.id, row.name)}
        type="button"
        text="MANAGE ATTRIBUTES"
      />
    </div>
  );

  useEffect(() => {
    const loadOffices = async () => {
      setIsLoading(true);

      let hasMorePages = true;
      let currentPage = 0;
      const result: IOffice[] = [];

      while (hasMorePages) {
        currentPage += 1;
        const apiPage = await getOffices({
          pageNumber: currentPage,
          pageSize: 100,
        });

        if (!apiPage.success) {
          return [];
        } else {
          result.push(...(apiPage.data?.items ?? []));
          hasMorePages = apiPage.data?.hasNextPage ?? false;
        }
      }
      setOffices(result);
      setIsLoading(false);
    };

    loadOffices();
  }, []);

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      {isLoading && <Loading />}
      {!isLoading && (
        <Row className={css.bottompad2x}>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <Row>
              <Breadcrumb items={breadcrumbs} />
              <Col md={12} xs={12}>
                <div className={css.header}>YOUR OFFICES</div>
              </Col>
            </Row>

            <Row className={css.tableContainer}>
              <Table
                data={offices}
                noData="Your firm currently has no offices"
                headers={["OFFICE NAME"]}
                renderCell={renderCell}
                renderActions={renderActions}
              />
            </Row>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      )}
    </Layout>
  );
}

export default Offices;
