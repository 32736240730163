import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
} from 'react';
import PropTypes from 'prop-types';

interface IConfiguration {
  apiBaseAddress: string,
  cdnBaseAddress: string,
}

interface IConfigurationProviderProps {
  children: ReactNode,
}

const configuration: IConfiguration = {
  apiBaseAddress: window?.config?.apiBaseUrl,
  cdnBaseAddress: window?.config?.cdnUrl,
};

const ConfigurationContext = createContext<IConfiguration>({
  ...configuration,
});

export function useConfiguration(): IConfiguration {
  return useContext(ConfigurationContext);
}

export function ConfigurationProvider({ children }: IConfigurationProviderProps) {
  const [config, setConfig] = useState<IConfiguration>({
    ...configuration,
  });

  const obj = {
    ...config,
  };

  return (
    <ConfigurationContext.Provider value={obj}>
      {children}
    </ConfigurationContext.Provider>
  );
}

ConfigurationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
