//QUOTE
export const STORE_QUOTE_TYPE = "STORE_QUOTE_TYPE";
export const STORE_QUOTE_REGION = "STORE_QUOTE_REGION";
export const STORE_QUOTE_PANEL = "STORE_QUOTE_PANEL";

export const STORE_QUOTE_PROPERTY_ADDRESS = "STORE_QUOTE_PROPERTY_ADDRESS";

export const STORE_QUOTE_PROPERTY_TYPE = "STORE_QUOTE_PROPERTY_TYPE";
export const STORE_QUOTE_CUSTOMER_LOCATION = "STORE_QUOTE_CUSTOMER_LOCATION";
export const STORE_QUOTE_TRANSACTION_AMOUNT = "STORE_QUOTE_TRANSACTION_AMOUNT";
export const STORE_QUOTE_TENURE = "STORE_QUOTE_TENURE";
export const STORE_QUOTE_MORTGAGE = "STORE_QUOTE_MORTGAGE";
export const STORE_QUOTE_OBTAIN_MORTGAGE = "STORE_QUOTE_OBTAIN_MORTGAGE";
export const STORE_QUOTE_LTD_COMPANY = "STORE_QUOTE_LTD COMPANY";
export const STORE_QUOTE_FIRST_TIME_BUYER = "STORE_QUOTE_FIRST_TIME_BUYER";
export const STORE_QUOTE_FIRST_REGISTRATION_TRAIT =
  "STORE_QUOTE_FIRST_REGISTRATION_TRAIT";
export const STORE_QUOTE_TRANSFER_REQUIRED = "STORE_QUOTE_TRANSFER_REQUIRED";
export const STORE_QUOTE_MAIN_RESIDENCE = "STORE_QUOTE_MAIN_RESIDENCE";
export const STORE_QUOTE_OTHER_RESIDENCE = "STORE_QUOTE_OTHER_RESIDENCE";
export const STORE_QUOTE_NEW_LENDER = "STORE_QUOTE_NEW_LENDER";
export const STORE_QUOTE_OWNERS = "STORE_QUOTE_OWNERS";
export const STORE_QUOTE_OWNERS_AFTER_TRANSFER =
  "STORE_QUOTE_OWNERS_AFTER_TRANSFER";
export const STORE_QUOTE_TRAITS = "STORE_QUOTE_TRAITS";
export const STORE_QUOTE_ARMED_FORCES_TRAIT = "STORE_QUOTE_ARMED_FORCES_TRAIT";
export const STORE_QUOTE_AUCTION_TRAIT = "STORE_QUOTE_AUCTION_TRAIT";
export const STORE_QUOTE_DEED_OF_POSTPONEMENT_TRAIT =
  "STORE_QUOTE_DEED_OF_POSTPONEMENT_TRAIT";
export const STORE_QUOTE_EXPATS_TRAIT = "STORE_QUOTE_EXPATS_TRAIT";
export const STORE_QUOTE_NUMBER_OF_EXPATS = "STORE_QUOTE_NUMBER_OF_EXPATS";
export const STORE_QUOTE_GIFTED_DEPOSIT_TRAIT =
  "STORE_QUOTE_GIFTED_DEPOSIT_TRAIT";
export const STORE_QUOTE_NUMBER_OF_GIFTED_DEPOSITS =
  "STORE_QUOTE_NUMBER_OF_GIFTED_DEPOSITS";
export const STORE_QUOTE_GIFTED_EQUITY_TRAIT =
  "STORE_QUOTE_GIFTED_EQUITY_TRAIT";
export const STORE_QUOTE_HELP_TO_BUY_TRAIT = "STORE_QUOTE_HELP_TO_BUY_TRAIT";
export const STORE_QUOTE_NUMBER_OF_HELP_TO_BUY =
  "STORE_QUOTE_NUMBER_OF_HELP_TO_BUY";
export const STORE_QUOTE_HELP_TO_BUY_LOAN_TRAIT =
  "STORE_QUOTE_HELP_TO_BUY_LOAN_TRAIT";
export const STORE_QUOTE_HMO_TRAIT = "STORE_QUOTE_HMO_TRAIT";
export const STORE_QUOTE_STUDENT_PROPERTY = "STORE_QUOTE_STUDENT_PROPERTY";
export const STORE_QUOTE_JOINT_PROPRIETOR_TRAIT =
  "STORE_QUOTE_JOINT_PROPRIETOR_TRAIT";
export const STORE_QUOTE_SOLE_PROPRIETOR_TRAIT =
  "STORE_QUOTE_SOLE_PROPRIETOR_TRAIT";
export const STORE_QUOTE_NEW_BUILD_TRAIT = "STORE_QUOTE_NEW_BUILD_TRAIT";
export const STORE_QUOTE_RIGHT_TO_BUY_TRAIT = "STORE_QUOTE_RIGHT_TO_BUY_TRAIT";
export const STORE_QUOTE_SHARED_OWNERSHIP_TRAIT =
  "STORE_QUOTE_SHARED_OWNERSHIP_TRAIT";
export const STORE_QUOTE_HIGH_RISE_FLAT_TRAIT =
  "STORE_QUOTE_HIGH_RISE_FLAT_TRAIT";
export const RESET_QUOTE_DATA = "RESET_QUOTE_DATA";
export const RESET_INSTRUCT_DATA = "RESET_INSTRUCT_DATA";
export const GET_SAVED_QUOTES_QUERY = "GET_SAVED_QUOTES_QUERY";
export const STORE_QUOTE_YEAR_BUILT = "STORE_YEAR_BUILT";
export const STORE_QUOTE_NUMBER_OF_BEDROOMS = "STORE_NUMBER_OF_BEDROOMS";
export const STORE_QUOTE_BRIDGING_FINANCE = "STORE_QUOTE_BRIDGING_FINANCE";
export const STORE_QUOTE_BRIDGING_FINANCE_REPRESENTATION =
  "STORE_QUOTE_BRIDGING_FINANCE_REPRESENTATION";

//BESPOKE REFERRAL FEE
export const STORE_QUOTE_BESPOKE_REFERRAL_FEE =
  "STORE_QUOTE_BESPOKE_REFERRAL_FEE";
export const STORE_QUOTE_UPDATE_DEFAULT_REFERRAL_FEE =
  "STORE_QUOTE_UPDATE_DEFAULT_REFERRAL_FEE";
export const VALIDATE_BESPOKE_REFERRAL_FEE = "VALIDATE_BESPOKE_REFERRAL_FEE";
export const BESPOKE_REFERRAL_FEE_VALIDATION_FAILURE =
  "BESPOKE_REFERRAL_FEE_VALIDATION_FAILURE";
export const BESPOKE_REFERRAL_FEE_VALIDATION_SUCCESS =
  "BESPOKE_REFERRAL_FEE_VALIDATION_SUCCESS";

//PREFERENCES
export const GET_PREFERENCES = "GET_PREFERENCES";
export const SET_PREFERENCES = "SET_PREFERENCES";
export const STORE_PREFERENCE_DEFAULT_REFERRAL_FEE =
  "STORE_PREFERENCE_DEFAULT_REFERRAL_FEE";
export const STORE_PREFERENCE_UINSURE_QUOTING_TYPE =
  "STORE_PREFERENCE_UINSURE_QUOTING_TYPE";
export const STORE_PREFERENCE_CONTACT_EMAIL = "STORE_PREFERENCE_CONTACT_EMAIL";
export const STORE_PREFERENCE_CONTACT_MOBILE =
  "STORE_PREFERENCE_CONTACT_MOBILE";
export const STORE_PREFERENCE_DEFAULT_JURISDICTION =
  "STORE_PREFERENCE_DEFAULT_JURISDICTION";
export const STORE_PREFERENCE_CASE_UPDATE_TYPE =
  "STORE_PREFERENCE_CASE_UPDATE_TYPE";
export const STORE_PREFERENCE_PREFERRED_PRICING_MODEL =
  "STORE_PREFERENCE_PREFERRED_PRICING_MODEL";
export const STORE_PREFERENCE_LAST_UPDATED = "STORE_PREFERENCE_LAST_UPDATED";
export const STORE_PREFERENCE_NETWORK = "STORE_PREFERENCE_NETWORK";
export const VALIDATE_PREFERENCES = "VALIDATE_PREFERENCES";
export const PREFERENCES_VALIDATION_FAILURE = "PREFERENCE_VALIDATION_FAILURE";
export const PREFERENCES_VALIDATION_SUCCESS = "PREFERENCE_VALIDATION_SUCCESS";
export const LINK_UINSURE = "LINK_UINSURE";
export const UINSURE_LINK_COMPLETE = "UINSURE_LINK_COMPLETE";

//GENERAL
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_LENDERS = "GET_LENDERS";
export const GET_PANELS = "GET_PANELS";
export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const SITE_ERROR = "SITE_ERROR";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_LENDERS = "SET_LENDERS";
export const SET_PANELS = "SET_PANELS";
export const VALIDATE_QUOTE = "VALIDATE_QUOTE";
export const SUBMIT_QUOTE = "SUBMIT_QUOTE";
export const SITE_BUSY = "SITE_BUSY";
export const QUOTE_VALIDATION_FAILURE = "QUOTE_VALIDATION_FAILURE";
export const QUOTE_VALIDATION_SUCCESS = "QUOTE_VALIDATION_SUCCESS";
export const QUOTES_RECEIVED = "QUOTES_RECEIVED";
export const STORE_DATE_OF_BIRTH = "STORE_DATE_OF_BIRTH";
export const STORE_UINSURE_LINK_ERROR = "STORE_UINSURE_LINK_ERROR";
export const STORE_UINSURE_VALIDATION = "STORE_UINSURE_VALIDATION";
export const GET_CURRENT_QUOTE_FROM_SAVED = "GET_CURRENT_QUOTE_FROM_SAVED";
export const SET_QUOTE_EDITING = "SET_QUOTE_EDITING";
export const SEND_SAVED_QUOTE_WITH_ID = "SEND_SAVED_QUOTE_WITH_ID";
export const SET_SELECTED_QUOTES = "SET_SELECTED_QUOTES";

//LOADING STATES
export const QUOTE_REQUEST_BUSY = "QUOTE_REQUEST_BUSY";
export const SEND_QUOTE_REQUEST_BUSY = "SEND_QUOTE_REQUEST_BUSY";
export const LINK_UINSURE_REQUEST_BUSY = "LINK_UINSURE_REQUEST_BUSY";

// UINSURE
export const STORE_UINSURE_QUOTE = "STORE_UINSURE_QUOTE";
export const GET_UINSURE_QUOTE = "GET_UINSURE_QUOTE";
export const GET_UINSURE_REDIRECT = "GET_UINSURE_REDIRECT";
export const STORE_UINSURE_SELECTED_QUOTE = "STORE_UINSURE_SELECTED_QUOTE";
export const STORE_UINSURE_INSTRUCT = "STORE_UINSURE_INSTRUCT";
export const VALIDATE_UINSURE_FORM = "VALIDATE_UINSURE_FORM";
export const STORE_UINSURE_VALIDATION_SUCCESS =
  "STORE_UINSURE_VALIDATION_SUCCESS";
export const UINSURE_SEND_LEAD = "UINSURE_SEND_LEAD";
export const UPDATE_UINSURE_CLIENT = "UPDATE_UINSURE_CLIENT";

// CASES
export const GET_SAVED_CASES = "GET_SAVED_CASES";
export const FINISHED_LOADING_CASES = "FINISHED_LOADING_CASES";
export const POPULATE_SAVED_CASES = "POPULATE_SAVED_CASES";
export const START_SEARCHING_CASES = "START_SEARCHING_CASES";
export const SEARCH_CASES = "SEARCH_CASES";

// EMULATE
export const FINISHED_GETTING_USER = "FINISHED_GETTING_USER";
export const POPULATE_EMULATE_USER = "POPULATE_EMULATE_USER";
export const GET_EMULATE_USER = "GET_EMULATE_USER";
export const START_EMULATING_USER_SAGA = "START_EMULATING_USER_SAGA";
export const START_EMULATING_USER = "START_EMULATING_USER";
export const STOP_EMULATING_USER = "STOP_EMULATING_USER";
export const STOP_EMULATING_USER_SAGA = "STOP_EMULATING_USER_SAGA";
export const RESET_STORE = "RESET_STORE";
