export interface IImage {
  path: string;
  alt: string;
}

export const deleteIcon: IImage = {
  path: "select/icons/delete.svg",
  alt: "Delete",
};

export const configureIcon: IImage = {
  path: "select/icons/cog.svg",
  alt: "Configure",
};

export const saveIcon: IImage = {
  path: "select/icons/save.svg",
  alt: "Save",
};

export const unSavedIcon: IImage = {
  path: "select/icons/save-orange.svg",
  alt: "Save",
};

export const resetIcon: IImage = {
  path: "select/icons/reset.svg",
  alt: "Reset",
};