import store, { persistor } from "./redux/store";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigurationProvider } from "./context/configuration-context";

import ScrollToTop from "./components/scrolltotop/scrolltotop";
import EmulateNotification from "./components/EmulateNotification";

import Footer from "./layout/footer/Footer";
import Header from "./layout/header/header";

import CaseProgress from "./pages/case";
import CaseDetail from "./pages/caseDetail";
import CreateAccount from "./pages/createaccount/createaccount";
import Dashboard from "./pages/dashboard/dashboard";
import InstructQuote from "./pages/instructQuote";
import Landing from "./pages/landing/Landing";
import Preferences from "./pages/preferences/preferences";
import Quote from "./pages/quote/quote";
import QuoteResults from "./pages/quoteresults/quoteresults";
import RenewOidc from "./pages/renew-oidc";
import SigninOidc from "./pages/signin-oidc";
import SignoutOidc from "./pages/signout-oidc";
import ViewQuote from "./pages/viewquote";
import GeneralInsuranceDetails from "./pages/general-insurance-details";
import GeneralInsuranceQuotes from "./pages/general-insurance-quotes";
import LegalTerms from "./pages/legalTerms";
import ViewSavedQuotes from "./pages/viewSavedQuotes";
import ViewSavedQuote from "./pages/viewSavedQuote";
import InstructConfirmation from "./pages/instructConfirmation";
import SiteNotifications from "./components/toast";
import ManagePanelMemberships from "./pages/law-firms/manage-panel-memberships";
import ManageOffices from "./pages/law-firms/offices";
import ManageOfficeAttributes from "./pages/law-firms/office-attributes";
import ManageOfficePricing from "./pages/law-firms/office-pricing";
import ManageProducts from "./pages/law-firms/products";

import AuthAwareRoute from "./utils/authAwareRoute";
import AuthProvider from "./utils/authProvider";
import PrivateRoute from "./utils/privateRoute";

import Error from "./pages/error";
import Error404 from "./pages/404";
import history from "./history";
import { PersistGate } from "redux-persist/integration/react";
import Spinner from "./components/spinner";
import ErrorBoundary from "./components/errorBoundary";
import FeatureFlagWrapper from "./components/feature-flag-wrapper";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/appInsights/ApplicationInsightsService";
import PermissionProvider from "./context/permission-context";

const App = () => {
  return (
    <Provider store={store}>
      <ConfigurationProvider>
        <PersistGate loading={<Spinner />} persistor={persistor}>
          <div>
            <AuthProvider>
              <PermissionProvider>
                <Router history={history} basename={process.env.PUBLIC_URL}>
                  <AppInsightsContext.Provider value={reactPlugin}>
                    <SiteNotifications />
                    <ScrollToTop />
                    <EmulateNotification />
                    <Header />
                    <ErrorBoundary redirect="/dashboard">
                      <FeatureFlagWrapper>
                        <Switch>
                          <AuthAwareRoute exact path="/" component={Landing} />
                          <Route
                            exact
                            path="/signout-oidc"
                            component={SignoutOidc}
                          />
                          <Route
                            exact
                            path="/signin-oidc"
                            component={SigninOidc}
                          />
                          <Route exact path="/renew-oidc" component={RenewOidc} />
                          <Route exact path="/error" component={Error} />
                          <Route exact path="/404" component={Error404} />
                          <Route
                            exact
                            path="/createaccount"
                            component={CreateAccount}
                          />
                          <AuthAwareRoute
                            exact
                            path="/quoteresults"
                            component={QuoteResults}
                          />
                          <AuthAwareRoute
                            exact
                            path="/preferences"
                            component={Preferences}
                          />
                          <AuthAwareRoute exact path="/quote" component={Quote} />
                          <AuthAwareRoute
                            exact
                            path="/dashboard"
                            component={Dashboard}
                          />
                          <AuthAwareRoute
                            exact
                            path="/view-quotes"
                            component={ViewSavedQuotes}
                          />
                          <AuthAwareRoute
                            exact
                            path="/view-quotes/:quoteGroupId"
                            component={ViewSavedQuote}
                          />
                          <AuthAwareRoute
                            exact
                            path="/view-quote"
                            component={ViewQuote}
                          />
                          <AuthAwareRoute
                            exact
                            path="/quote-further-details"
                            component={GeneralInsuranceDetails}
                          />
                          <AuthAwareRoute
                            exact
                            path="/quote-insurance"
                            component={GeneralInsuranceQuotes}
                          />
                          <AuthAwareRoute
                            exact
                            path="/instruct-quote/:quoteGroupId?"
                            component={({ match, ...rest }) => {
                              return (
                                <InstructQuote pathSlugs={match} {...rest} />
                              );
                            }}
                          />
                          <AuthAwareRoute
                            exact
                            path="/legalTerms/:quoteId?"
                            component={({ match, ...rest }) => {
                              return <LegalTerms pathSlugs={match} {...rest} />;
                            }}
                          />
                          <AuthAwareRoute
                            exact
                            path="/case-progress"
                            component={CaseProgress}
                          />
                          <AuthAwareRoute
                            exact
                            path="/caseDetail/:caseId"
                            component={CaseDetail}
                          />
                          <AuthAwareRoute
                            exact
                            path="/instruct-confirmation"
                            component={InstructConfirmation}
                          />
                          <AuthAwareRoute
                            exact
                            path="/manage-panels"
                            component={ManagePanelMemberships}
                          />
                          <AuthAwareRoute
                            exact
                            path="/manage-offices"
                            component={ManageOffices}
                          />
                          <AuthAwareRoute
                            exact
                            path="/manage-products"
                            component={ManageProducts}
                          />
                          <AuthAwareRoute
                            exact
                            path="/manage-office-attributes/:officeId"
                            component={({ match }) => {
                              return (
                                <ManageOfficeAttributes
                                  officeId={match.params.officeId}
                                />
                              );
                            }}
                          />
                          <AuthAwareRoute
                            exact
                            path="/manage-office-pricing/:officeId"
                            component={({ match }) => (
                              <ManageOfficePricing
                                officeId={match.params.officeId}
                              />
                            )}
                          />

                          <PrivateRoute
                            exact
                            path="/quote"
                            role="BROKER_USER.PAGE"
                            component={Quote}
                          />
                          <Route path="*" component={Error404} />
                        </Switch>
                      </FeatureFlagWrapper>
                    </ErrorBoundary>
                  </AppInsightsContext.Provider>
                </Router>
                <Footer />
              </PermissionProvider>
            </AuthProvider>
          </div>
        </PersistGate>
      </ConfigurationProvider>
    </Provider>
  );
};

export default App;
