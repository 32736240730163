import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import css from "./index.module.scss";

import AuthCheck from "../../../components/authcheck/authCheck";
import authState from "../../../components/authcheck/authState";
import Layout from "../../../layout";
import useSelectApi from "../../../hooks/useSelectApi";
import { IOffice, IProduct } from "../../../types/select-api/ResponseTypes";
import Loading from "../../../components/loading";
import Breadcrumb from "../components/breadcrumbs";
import Button from "../../../components/button/button";
import ProviderProducts from "../components/products";
import ManageLawFirmProductsModal from "../components/products/manage-product-modal";
import useRenderKey from "@lms/react-common-components/useRenderKey";
import { useNotifications } from "../../../hooks/notifications";
import EditPricingPermissionBanner from "../components/edit-pricing-permission-banner";

function Products() {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Array<IProduct>>([]);
  const [offices, setOffices] = useState<Array<IOffice>>([]);
  const { getProducts, getOffices } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: false,
  });
  const [showAddNewProduct, setShowAddNewProduct] = useState(false);
  const { renderKey, updateRenderKey } = useRenderKey();
  const { showSuccess } = useNotifications();

  const breadcrumbs = [
    { name: "Home", path: "/" },
    { name: "Products and Pricing", isActive: true },
  ];

  const handleAddNewProduct = () => setShowAddNewProduct(true);
  const handleAddNewProductClose = (saved: boolean) => {
    updateRenderKey();
    setShowAddNewProduct(false);
    if (saved) {
      showSuccess("Product assigned to law firm and selected offices");
    }
  };

  useEffect(() => {
    const loadProducts = async () => {
      setIsLoading(true);

      const apiPage = await getProducts();

      if (!apiPage.success) {
        return [];
      } else {
        setProducts(apiPage.data!);
      }
    };
    const loadOffices = async () => {
      const apiPage = await getOffices({ pageNumber: 1, pageSize: 100 });

      if (!apiPage.success) {
        return [];
      } else {
        setOffices(apiPage.data!.items);
      }

      setIsLoading(false);
    };

    loadProducts();
    loadOffices();
  }, [renderKey]);

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      {isLoading && <Loading />}
      {!isLoading && (
        <Row className={css.bottompad2x}>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <Row>
              <Col md={12} xs={12}>
                <Breadcrumb items={breadcrumbs} />
              </Col>
            </Row>
            <Row>
              <Col md={10} xs={10}>
                <div className={css.header}>YOUR PRODUCTS</div>
              </Col>
              <Col md={2} xs={2} className={css.buttonColumn}>
                <Button
                  id="add-product"
                  datatestid="add-product"
                  btnClass={css.addNewProductButton}
                  onClick={handleAddNewProduct}
                  type="button"
                  text="Add Product"
                />
              </Col>
            </Row>
            <EditPricingPermissionBanner />

            <Row className={css.tableContainer}>
              <Col md={12} xs={12}>
                <ProviderProducts
                  providerType="lawfirm"
                  products={products}
                  offices={offices}
                />
              </Col>
            </Row>
          </Col>
          <Col md={1} xs={1}></Col>
          <ManageLawFirmProductsModal
            open={showAddNewProduct}
            onClose={handleAddNewProductClose}
            products={products.filter((x) => x.assignedOn == null)}
            offices={offices.map((x) => ({
              id: x.id,
              name: x.name,
              hasProductAssigned: false,
            }))}
          />
        </Row>
      )}
    </Layout>
  );
}

export default Products;
