import React, { useContext } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../utils/authProvider";
import { useConfiguration } from "../context/configuration-context";
import { ISelectApiOptions } from "../types/select-api/ISelectApiOptions";
import { useNotifications } from "../hooks/notifications";
import * as ResponseTypes from "../types/select-api/ResponseTypes";
import _ from "lodash";
import {
  ICreateProductFeeBandRequest,
  IOfficeAttributesRequest,
  IPagedRequest,
  ISaveProductConditionsRequest,
  ISaveProductFeeRequest,
  ISaveProductRequest,
  IUpdateProductFeeBandRequest,
} from "../types/select-api/RequestTypes";
import { BandId } from "../pages/law-firms/types/product-types";

interface IErrorResult {
  success: false;
  error?: string;
}

interface ISuccessResult<T> {
  success: true;
  data: T;
}

type IResult<T> = ISuccessResult<T> | IErrorResult;

interface IHeaders extends Record<string, string> {
  Authorization: string;
}

interface IRequestConfig {
  headers: IHeaders;
}

export default function useSelectApi(
  options: ISelectApiOptions | undefined = undefined
) {
  const { apiBaseAddress } = useConfiguration();
  const history = useHistory();
  const { getAccessToken } = useContext(AuthContext);
  const { showError } = useNotifications();

  if (!apiBaseAddress) {
    console.error("ERROR: Cannot set API base address");
  }

  const authenticate = async (): Promise<IRequestConfig> => {
    const accessToken = await getAccessToken();
    return {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    };
  };

  const handleUnauthorised = () => {
    if (!options?.suppressErrorRedirect) {
      history.push("/no-auth");
    }
  };

  interface ISelectApiError {
    title?: string;
    detail?: string;
  }

  const showErrorMessage = (e: AxiosError<ISelectApiError>) => {
    if (!options?.suppressErrorMessages) {
      if (e.response?.data?.detail) {
        showError(e.response.data.detail);
      } else {
        showError("An error occurred");
      }
    }
  };

  const execute = async <T>(
    fn: (cfg: IRequestConfig) => Promise<T>
  ): Promise<IResult<T>> => {
    let error = undefined;
    try {
      const config = await authenticate();
      const data = await fn(config);
      return { success: true, data };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e?.response?.status === 401) {
          handleUnauthorised();
          if (
            e.response?.data?.detail?.includes(
              "Missing role. Expected LMS_SELECT_UPDATE_FEES_ADMIN.PAGE"
            )
          ) {
            error =
              "You don't have permission to change these settings. If you wish to make changes, please ask the Admin at your organisation.";
          }
        } else {
          showErrorMessage(e);
        }
      } else {
        showError("An error occurred");
      }
    }

    return { success: false, error };
  };

  const searchAddress = async (
    searchTerm: string
  ): Promise<IResult<ResponseTypes.IAddressSearchResultItem[]>> =>
    execute(async (cfg): Promise<ResponseTypes.IAddressSearchResultItem[]> => {
      const url = `${apiBaseAddress}/addresses?searchTerm=${searchTerm}`;
      const { data } = await axios.get<
        ResponseTypes.IAddressSearchResultItem[]
      >(url, cfg);
      return data;
    });

  const drillDownAddress = async (
    containerId: string
  ): Promise<IResult<ResponseTypes.IAddressSearchResultItem[]>> =>
    execute(async (cfg): Promise<ResponseTypes.IAddressSearchResultItem[]> => {
      const url = `${apiBaseAddress}/addresses?containerid=${containerId}`;
      const { data } = await axios.get<
        ResponseTypes.IAddressSearchResultItem[]
      >(url, cfg);
      return data;
    });

  const lookupAddress = async (
    addressId: string
  ): Promise<IResult<ResponseTypes.IAddressGetResult>> =>
    execute(async (cfg): Promise<ResponseTypes.IAddressGetResult> => {
      const url = `${apiBaseAddress}/addresses/${addressId}`;
      const { data } = await axios.get<ResponseTypes.IAddressGetResult>(
        url,
        cfg
      );
      return data;
    });

  const getPanels = async (
    request: IPagedRequest,
    officeId?: ResponseTypes.ProviderId
  ): Promise<
    IResult<ResponseTypes.IPagedResponse<ResponseTypes.IMemberPanel>>
  > =>
    execute(
      async (
        cfg
      ): Promise<ResponseTypes.IPagedResponse<ResponseTypes.IMemberPanel>> => {
        let url = `${apiBaseAddress}/panels?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}`;
        if (officeId) {
          url += `&officeId=${officeId}`;
        }
        const { data } = await axios.get<
          ResponseTypes.IPagedResponse<ResponseTypes.IMemberPanel>
        >(url, cfg);
        return data;
      }
    );

  const getOffices = async (
    request: IPagedRequest
  ): Promise<IResult<ResponseTypes.IPagedResponse<ResponseTypes.IOffice>>> =>
    execute(
      async (
        cfg
      ): Promise<ResponseTypes.IPagedResponse<ResponseTypes.IOffice>> => {
        const url = `${apiBaseAddress}/offices?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}`;
        const { data } = await axios.get<
          ResponseTypes.IPagedResponse<ResponseTypes.IOffice>
        >(url, cfg);
        return data;
      }
    );

  const getOffice = async (
    officeId: ResponseTypes.ProviderId
  ): Promise<IResult<ResponseTypes.IOffice>> =>
    execute(async (cfg): Promise<ResponseTypes.IOffice> => {
      const url = `${apiBaseAddress}/offices/${officeId}`;
      const { data } = await axios.get<ResponseTypes.IOffice>(url, cfg);
      return data;
    });

  const getOfficeAttributes = async (
    officeId: ResponseTypes.ProviderId
  ): Promise<IResult<Array<ResponseTypes.IOfficeAttributeCategory>>> =>
    execute(
      async (cfg): Promise<Array<ResponseTypes.IOfficeAttributeCategory>> => {
        const url = `${apiBaseAddress}/offices/${officeId}/attributes`;
        const { data } = await axios.get<
          Array<ResponseTypes.IOfficeAttributeCategory>
        >(url, cfg);
        return data;
      }
    );

  const saveOfficeAttributes = async (
    officeId: ResponseTypes.ProviderId,
    request: IOfficeAttributesRequest
  ): Promise<IResult<void>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.put(
        `${apiBaseAddress}/offices/${officeId}/attributes`,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const getProducts = async (): Promise<
    IResult<Array<ResponseTypes.IProduct>>
  > =>
    execute(async (cfg): Promise<Array<ResponseTypes.IProduct>> => {
      const url = `${apiBaseAddress}/products`;
      const { data } = await axios.get<Array<ResponseTypes.IProduct>>(url, cfg);
      return data;
    });

  const saveProduct = async (
    productId: ResponseTypes.ProductId,
    request: ISaveProductRequest
  ): Promise<IResult<ResponseTypes.IProduct>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.put<ResponseTypes.IProduct>(
        `${apiBaseAddress}/products/${productId}`,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const deleteProduct = async (
    productId: ResponseTypes.ProductId
  ): Promise<IResult<void>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.delete(
        `${apiBaseAddress}/products/${productId}`,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const deleteOfficeProduct = async (
    officeId: ResponseTypes.ProviderId,
    productId: ResponseTypes.ProductId
  ): Promise<IResult<void>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.delete(
        `${apiBaseAddress}/offices/${officeId}/products/${productId}`,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const getProductConditions = async (
    productId: ResponseTypes.ProductId
  ): Promise<IResult<ResponseTypes.IProductConditions>> =>
    execute(async (cfg): Promise<ResponseTypes.IProductConditions> => {
      const url = `${apiBaseAddress}/products/${productId}/conditions`;
      const { data } = await axios.get<ResponseTypes.IProductConditions>(
        url,
        cfg
      );
      return data;
    });

  const saveProductConditions = async (
    productId: ResponseTypes.ProductId,
    request: ISaveProductConditionsRequest
  ): Promise<IResult<ResponseTypes.IProductConditions>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.put<ResponseTypes.IProductConditions>(
        `${apiBaseAddress}/products/${productId}/conditions`,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const getOfficeProductFees = async (
    officeId: ResponseTypes.ProviderId,
    productId: ResponseTypes.ProductId,
    panelId: ResponseTypes.PanelId | undefined
  ): Promise<IResult<Array<ResponseTypes.IProductFee>>> =>
    execute(async (cfg): Promise<Array<ResponseTypes.IProductFee>> => {
      let url = `${apiBaseAddress}/offices/${officeId}/products/${productId}/fees`;
      if (panelId) {
        url += `?panelId=${panelId}`;
      }
      const { data } = await axios.get<Array<ResponseTypes.IProductFee>>(
        url,
        cfg
      );
      return data;
    });

  const getProductFees = async (
    productId: ResponseTypes.ProductId
  ): Promise<IResult<Array<ResponseTypes.IProductFee>>> =>
    execute(async (cfg): Promise<Array<ResponseTypes.IProductFee>> => {
      const url = `${apiBaseAddress}/products/${productId}/fees`;
      const { data } = await axios.get<Array<ResponseTypes.IProductFee>>(
        url,
        cfg
      );
      return data;
    });

  const getProductFeeBands = async (
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId
  ): Promise<IResult<Array<ResponseTypes.IProductFeeBand>>> =>
    execute(async (cfg): Promise<Array<ResponseTypes.IProductFeeBand>> => {
      const url = `${apiBaseAddress}/products/${productId}/fees/${feeId}/bands`;
      const { data } = await axios.get<Array<ResponseTypes.IProductFeeBand>>(
        url,
        cfg
      );
      return data;
    });

  const createProductFeeBand = async (
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    request: ICreateProductFeeBandRequest
  ): Promise<IResult<ResponseTypes.IProductFeeBand>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.post<ResponseTypes.IProductFeeBand>(
        `${apiBaseAddress}/products/${productId}/fees/${feeId}/bands`,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const updateProductFeeBand = async (
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    request: IUpdateProductFeeBandRequest
  ): Promise<IResult<ResponseTypes.IProductFeeBand>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.put<ResponseTypes.IProductFeeBand>(
        `${apiBaseAddress}/products/${productId}/fees/${feeId}/bands/${request.id}`,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const deleteProductFeeBand = async (
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    bandId: BandId
  ): Promise<IResult<void>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.delete(
        `${apiBaseAddress}/products/${productId}/fees/${feeId}/bands/${bandId}`,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const saveProductFee = async (
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    request: ISaveProductFeeRequest
  ): Promise<IResult<ResponseTypes.IProductFee>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.put<ResponseTypes.IProductFee>(
        `${apiBaseAddress}/products/${productId}/fees/${feeId}`,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const deleteProductFee = async (
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId
  ): Promise<IResult<void>> =>
    execute(async (cfg: IRequestConfig) => {
      const { data } = await axios.delete(
        `${apiBaseAddress}/products/${productId}/fees/${feeId}`,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const saveOfficeProductFee = async (
    officeId: ResponseTypes.ProviderId,
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    panelId: ResponseTypes.PanelId | undefined,
    request: ISaveProductFeeRequest
  ): Promise<IResult<ResponseTypes.IProductFee>> =>
    execute(async (cfg: IRequestConfig) => {
      let url = `${apiBaseAddress}/offices/${officeId}/products/${productId}/fees/${feeId}`;
      if (panelId) {
        url += `?panelId=${panelId}`;
      }
      const { data } = await axios.put<ResponseTypes.IProductFee>(
        url,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const deleteOfficeProductFee = async (
    officeId: ResponseTypes.ProviderId,
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    panelId: ResponseTypes.PanelId | undefined
  ): Promise<IResult<void>> =>
    execute(async (cfg: IRequestConfig) => {
      let url = `${apiBaseAddress}/offices/${officeId}/products/${productId}/fees/${feeId}`;
      if (panelId) {
        url += `?panelId=${panelId}`;
      }
      const { data } = await axios.delete(url, cfg as AxiosRequestConfig);
      return data;
    });

  const getOfficeProductFeeBands = async (
    officeId: ResponseTypes.ProviderId,
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    panelId: ResponseTypes.PanelId | undefined
  ): Promise<IResult<Array<ResponseTypes.IProductFeeBand>>> =>
    execute(async (cfg): Promise<Array<ResponseTypes.IProductFeeBand>> => {
      let url = `${apiBaseAddress}/offices/${officeId}/products/${productId}/fees/${feeId}/bands`;
      if (panelId) {
        url += `?panelId=${panelId}`;
      }
      const { data } = await axios.get<Array<ResponseTypes.IProductFeeBand>>(
        url,
        cfg
      );
      return data;
    });

  const createOfficeProductFeeBand = async (
    officeId: ResponseTypes.ProviderId,
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    panelId: ResponseTypes.PanelId | undefined,
    request: ICreateProductFeeBandRequest
  ): Promise<IResult<ResponseTypes.IProductFeeBand>> =>
    execute(async (cfg: IRequestConfig) => {
      let url = `${apiBaseAddress}/offices/${officeId}/products/${productId}/fees/${feeId}/bands`;
      if (panelId) {
        url += `?panelId=${panelId}`;
      }
      const { data } = await axios.post<ResponseTypes.IProductFeeBand>(
        url,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const updateOfficeProductFeeBand = async (
    officeId: ResponseTypes.ProviderId,
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    panelId: ResponseTypes.PanelId | undefined,
    request: IUpdateProductFeeBandRequest
  ): Promise<IResult<ResponseTypes.IProductFeeBand>> =>
    execute(async (cfg: IRequestConfig) => {
      let url = `${apiBaseAddress}/offices/${officeId}/products/${productId}/fees/${feeId}/bands/${request.id}`;
      if (panelId) {
        url += `?panelId=${panelId}`;
      }
      const { data } = await axios.put<ResponseTypes.IProductFeeBand>(
        url,
        request,
        cfg as AxiosRequestConfig
      );
      return data;
    });

  const deleteOfficeProductFeeBand = async (
    officeId: ResponseTypes.ProviderId,
    productId: ResponseTypes.ProductId,
    feeId: ResponseTypes.FeeId,
    bandId: BandId,
    panelId: ResponseTypes.PanelId | undefined
  ): Promise<IResult<void>> =>
    execute(async (cfg: IRequestConfig) => {
      let url = `${apiBaseAddress}/offices/${officeId}/products/${productId}/fees/${feeId}/bands/${bandId}`;
      if (panelId) {
        url += `?panelId=${panelId}`;
      }
      const { data } = await axios.delete(url, cfg as AxiosRequestConfig);
      return data;
    });

  return {
    searchAddress,
    drillDownAddress,
    lookupAddress,
    getPanels,
    getOffices,
    getOffice,
    getOfficeAttributes,
    saveOfficeAttributes,
    getProducts,
    saveProduct,
    deleteProduct,
    deleteOfficeProduct,
    getProductConditions,
    saveProductConditions,
    getOfficeProductFees,
    getProductFees,
    saveProductFee,
    deleteProductFee,
    saveOfficeProductFee,
    deleteOfficeProductFee,
    getProductFeeBands,
    createProductFeeBand,
    updateProductFeeBand,
    deleteProductFeeBand,
    getOfficeProductFeeBands,
    createOfficeProductFeeBand,
    updateOfficeProductFeeBand,
    deleteOfficeProductFeeBand,
  };
}
