import { useState, useCallback, useEffect } from "react";
import useSelectApi from "../../../../hooks/useSelectApi";
import {
  IMemberPanel,
  ProviderId,
} from "../../../../types/select-api/ResponseTypes";

const useOfficePanels = (officeId: ProviderId) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [panels, setPanels] = useState<Array<IMemberPanel>>([]);
  const { getPanels } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: false,
  });

  const getOfficePanels = useCallback(async (officeId: ProviderId) => {
    let hasMorePages = true;
    const result: IMemberPanel[] = [];

    for (let currentPage = 1; hasMorePages; currentPage++) {
      const apiPage = await getPanels(
        {
          pageNumber: currentPage,
          pageSize: 100,
        },
        officeId
      );

      if (!apiPage.success) return [];

      result.push(...(apiPage.data?.items ?? []));
      hasMorePages = apiPage.data?.hasNextPage ?? false;
    }

    setPanels(result);
  }, []);

  useEffect(() => {
    if (!officeId) return;
    setLoading(true);
    getOfficePanels(officeId)
      .then(() => setLoading(false))
      .catch(console.error);
  }, [officeId, getOfficePanels]);

  return {
    loading,
    panels,
  };
};

export default useOfficePanels;
