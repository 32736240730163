import React, { useEffect, useState } from "react";
import css from "./index.module.scss";
import { Button, Table } from "react-bootstrap";
import useSelectApi from "../../../../../../hooks/useSelectApi";
import Loading from "../../../../../../components/loading";
import {
  generateTempId,
  isTempId,
  TempId,
} from "../../../../../../types/temp-id";
import { useNotifications } from "../../../../../../hooks/notifications";
import * as notifications from "../../../../../../data/notifications";
import { IFeeBand, IFeeBandErrors } from "./types";
import FeeBandRow from "./fee-band-row";
import {
  FeeId,
  PanelId,
  ProductId,
  ProviderId,
} from "../../../../../../types/select-api/ResponseTypes";
import { BandId } from "../../../../types/product-types";
import { usePermissionContext } from "../../../../../../context/permission-context";

interface IProductFeeBandsProps {
  officeId?: ProviderId;
  panelId?: PanelId;
  productId: ProductId;
  feeId: FeeId;
}

const defaultDirtyFlags = {
  lowerBand: false,
  upperBand: false,
  netPrice: false,
  internationalNetPrice: false,
};

const defaultErrors = {
  invalidLowerBand: false,
  lowerBandExceedsUpperBand: false,
  invalidUpperBand: false,
  invalidNetPrice: false,
  invalidInternationalPrice: false,
  bandingOverlap: false,
};

function isDirty(feeBand: IFeeBand): boolean {
  return Object.values(feeBand.dirtyFlags).some((flag) => flag === true);
}

function isInError(errors: IFeeBandErrors): boolean {
  return Object.values(errors).some((error) => error === true);
}

const ProductFeeBands = ({
  officeId,
  productId,
  feeId,
  panelId,
}: IProductFeeBandsProps) => {
  const [feeBands, setFeeBands] = useState<IFeeBand[]>([]);
  const [loading, setLoading] = useState(true);
  const { showSuccess, showError } = useNotifications();
  const { canEditPricing } = usePermissionContext();

  const api = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: true,
  });

  useEffect(() => {
    const fetchFeeBands = async () => {
      setLoading(true);
      const apiResult = officeId
        ? await api.getOfficeProductFeeBands(
            officeId,
            productId,
            feeId,
            panelId
          )
        : await api.getProductFeeBands(productId, feeId);
      if (apiResult.success) {
        const rowData = apiResult.data.map((feeBand) => ({
          ...feeBand,
          hasInternationalNetPrice: feeBand.internationalNetPrice != undefined,
          errors: defaultErrors,
          dirtyFlags: defaultDirtyFlags,
        }));
        setFeeBands(rowData);
      }
      setLoading(false);
    };

    fetchFeeBands();
  }, [productId, feeId, panelId]);

  const validateFeeBand = (feeBand: IFeeBand) => {
    let errors: IFeeBandErrors = { ...defaultErrors };

    if (feeBand.lowerBand != undefined && feeBand.lowerBand < 0) {
      errors.invalidLowerBand = true;
    }

    if (feeBand.upperBand != undefined && feeBand.upperBand < 0) {
      errors.invalidUpperBand = true;
    }

    if (!feeBand.lowerBand && !feeBand.upperBand) {
      errors.invalidLowerBand = true;
      errors.invalidUpperBand = true;
    }

    if (
      feeBand.lowerBand != undefined &&
      feeBand.upperBand != undefined &&
      !errors.invalidLowerBand &&
      !errors.invalidUpperBand &&
      feeBand.lowerBand >= feeBand.upperBand
    ) {
      errors.lowerBandExceedsUpperBand = true;
    }

    if (
      feeBand.netPrice == undefined ||
      Number.isNaN(feeBand.netPrice) ||
      feeBand.netPrice <= 0
    ) {
      errors.invalidNetPrice = true;
    }

    if (
      feeBand.hasInternationalNetPrice &&
      (feeBand.internationalNetPrice == undefined ||
        Number.isNaN(feeBand.internationalNetPrice) ||
        feeBand.internationalNetPrice <= 0)
    ) {
      errors.invalidInternationalPrice = true;
    }

    // feeBands.forEach((fb) => {
    //   if (fb.id !== feeBand.id) {
    //     if (
    //       (feeBand.lowerBand !== undefined &&
    //         feeBand.upperBand !== undefined &&
    //         ((feeBand.lowerBand >= fb.lowerBand! &&
    //           feeBand.lowerBand <= fb.upperBand!) ||
    //           (feeBand.upperBand >= fb.lowerBand! &&
    //             feeBand.upperBand <= fb.upperBand!))) ||
    //       (feeBand.lowerBand !== undefined &&
    //         feeBand.lowerBand >= fb.lowerBand! &&
    //         feeBand.lowerBand <= fb.upperBand!) ||
    //       (feeBand.upperBand !== undefined &&
    //         feeBand.upperBand >= fb.lowerBand! &&
    //         feeBand.upperBand <= fb.upperBand!)
    //     ) {
    //       errors.bandingOverlap = true;
    //     }
    //   }
    // });

    setFeeBands((prevFeeBands) =>
      prevFeeBands.map((fb) =>
        fb.id === feeBand.id ? { ...feeBand, errors: errors } : fb
      )
    );

    return !isInError(errors);
  };

  const handleInputChange = (
    id: string,
    field: keyof IFeeBand,
    value: string | number | boolean | undefined
  ) => {
    const updateFeeBand = (feeBand: IFeeBand) => {
      if (feeBand.id !== id) return feeBand;

      const isDirty = feeBand[field] !== value;

      const updatedFeeBand = {
        ...feeBand,
        [field]: value,
        dirtyFlags: {
          ...feeBand.dirtyFlags,
          [field]: isDirty,
        },
      };

      validateFeeBand(updatedFeeBand);

      return updatedFeeBand;
    };

    setFeeBands((prevFeeBands) => prevFeeBands.map(updateFeeBand));
  };

  const handleSave = async (feeBand: IFeeBand) => {
    if (!validateFeeBand(feeBand)) return;

    const createOrUpdate = () => {
      const requestBody = {
        lowerBand: feeBand.lowerBand,
        upperBand: feeBand.upperBand,
        netPrice: feeBand.netPrice!,
        internationalNetPrice: feeBand.internationalNetPrice || null,
      };

      if (isTempId(feeBand.id)) {
        return officeId
          ? api.createOfficeProductFeeBand(
              officeId,
              productId,
              feeId,
              panelId,
              requestBody
            )
          : api.createProductFeeBand(productId, feeId, requestBody);
      }

      const updateRequestBody = {
        id: feeBand.id,
        ...requestBody,
      };

      return officeId
        ? api.updateOfficeProductFeeBand(
            officeId,
            productId,
            feeId,
            panelId,
            updateRequestBody
          )
        : api.updateProductFeeBand(productId, feeId, updateRequestBody);
    };

    const saveResult = await createOrUpdate();

    if (saveResult.success) {
      showSuccess(notifications.saveProductFeeBand.positive);
      setFeeBands((prevFeeBands) =>
        prevFeeBands.map((fb) =>
          fb.id === feeBand.id
            ? {
                ...feeBand,
                id: saveResult.data.id,
                dirtyFlags: defaultDirtyFlags,
              }
            : fb
        )
      );
    } else {
      showError(saveResult.error ?? notifications.saveProductFeeBand.negative);
    }
  };

  const handleDelete = async (bandId: BandId | TempId) => {
    if (isTempId(bandId)) {
      const newBands = feeBands.filter((b) => b.id !== bandId);
      setFeeBands(newBands);
      return;
    }

    const deleteResult = officeId
      ? await api.deleteOfficeProductFeeBand(
          officeId,
          productId,
          feeId,
          bandId,
          panelId
        )
      : await api.deleteProductFeeBand(productId, feeId, bandId);

    if (deleteResult.success) {
      showSuccess(notifications.deleteProductFeeBand.positive);
      const newBands = feeBands.filter((b) => b.id !== bandId);
      setFeeBands(newBands);
    } else {
      showError(
        deleteResult.error ?? notifications.deleteProductFeeBand.negative
      );
    }
  };

  const handleAddNewBand = () => {
    const newBands = feeBands.concat({
      id: generateTempId(),
      lowerBand: undefined,
      upperBand: undefined,
      netPrice: undefined,
      hasInternationalNetPrice: false,
      internationalNetPrice: undefined,
      errors: defaultErrors,
      dirtyFlags: defaultDirtyFlags,
    });
    setFeeBands(newBands);
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div className={css.table}>
          <Table aria-label="fee bands table" responsive>
            <thead className={css.tableHeader}>
              <div className={css.bandingHeader}>
                Any value that falls outside of the bands will be subject to the
                base fee
              </div>
              <div className={css.internationalHeader}>
                Is international the same?
              </div>
            </thead>
            <tbody>
              {feeBands.map((feeBand) => (
                <FeeBandRow
                  key={feeBand.id}
                  {...{
                    feeBand,
                    handleInputChange,
                    handleSave,
                    handleDelete,
                    isDirty,
                    isInError,
                  }}
                />
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {canEditPricing && (
        <div className={css.addButtonContainer}>
          <Button onClick={handleAddNewBand}>ADD NEW BAND</Button>
        </div>
      )}
    </>
  );
};

export default ProductFeeBands;
