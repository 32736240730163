import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { Search } from "react-bootstrap-icons";
import css from "./index.module.css";
import Select, { components, MenuListProps } from "react-select";
import useSelectApi from "../../../hooks/useSelectApi";
import Spinner from "../../spinner";
import { isBlank } from "../../../utils/jsutils";

const LoadingIndicator = () => {
  return <Spinner variant={css.spinner} />;
};

const menuHeaderStyle = {
  padding: "8px 12px",
  background: "black",
  color: "white",
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={classnames(css.menuItem)}>
        <span className={css.menuItemAddress}>{props.label}</span>
        <i
          className={classnames(
            css.arrow,
            css.right,
            props.data.type === "Address" ? css.disabled : ""
          )}
        ></i>
      </div>
    </components.Option>
  );
};

const AddressSearch = ({ id, state, setState, showValidationMessages }) => {
  const [options, setOptions] = useState([]);
  const [bindingFromState, setBindingFromState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [placeholder, setPlaceholder] = useState(
    "Start typing to find address..."
  );
  const [results, setResults] = useState([]);
  const { searchAddress, drillDownAddress, lookupAddress } = useSelectApi({
    suppressErrorRedirect: true,
  });
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const debounceTimeout = useRef();

  useEffect(() => {
    const label = buildLabel();
    if (!isBlank(label)) {
      setBindingFromState(true);
      setPlaceholder(buildLabel());
      setBindingFromState(false);
    }
  }, [state]);

  const buildLabel = () => {
    const {
      houseName,
      houseNumber,
      streetName,
      postTown,
      county,
      country,
      postCode,
    } = state.data;
    let houseNumberStreetName = null;
    if (houseNumber && streetName)
      houseNumberStreetName = `${houseNumber} ${streetName}`;
    else if (houseNumber) houseNumberStreetName = houseNumber;
    else if (streetName) houseNumberStreetName = streetName;

    const array = [
      houseName,
      houseNumberStreetName,
      postTown,
      county,
      postCode,
      country,
    ].filter((item) => item && item.length !== 0);
    return array.join(", ").replace(/\s+/g, " ");
  };

  const handleSelection = (item) => {
    const mapped = mapAddress(item);
    if (setState) setState({ ...state, data: mapped });
  };

  const mapAddress = (address) => {
    if (!address) return;
    const caseProperty = {};
    const {
      subBuilding,
      buildingName,
      buildingNumber,
      street,
      district,
      city,
      provinceName,
      countryName,
      postalCode,
    } = address;
    let houseName = [];
    let joiner = ", ";
    if (subBuilding) {
      houseName.push(subBuilding);
      if (subBuilding.match(/^\d+$/)) {
        joiner = " ";
      }
    }
    if (buildingName) houseName.push(buildingName);
    caseProperty.houseNumber = buildingNumber || "";
    caseProperty.houseName = houseName.join(joiner);
    caseProperty.streetName = street || "";
    caseProperty.districtName = district || "";
    caseProperty.postTown = city || "";
    caseProperty.county = provinceName || "";
    caseProperty.postCode = postalCode;
    caseProperty.country = countryName || "";
    return caseProperty;
  };

  useEffect(() => {
    if (!bindingFromState && searchTerm != "") {
      const fetchData = async () => {
        const { data } = await searchAddress(searchTerm);
        const mappedData = data.map((item) => {
          return {
            value: item.id,
            label: createSearchResultLabel(item),
            type: item.type,
          };
        });
        setOptions(mappedData);
        setIsLoading(false);
        setResults(data);
        setMenuIsOpen(true);
      };
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        setIsLoading(true);
        fetchData();
      }, 1000);
    }
    setMenuIsOpen(false);
    setOptions([]);
  }, [searchTerm]);

  const createSearchResultLabel = (searchResult) =>
    searchResult.type === "Address"
      ? `${searchResult.address}, ${searchResult.description}`
      : `${searchResult.address} - ${searchResult.description}`;

  const handleInputChange = (inputValue) => {
    if (inputValue?.length > 2) {
      setSearchTerm(inputValue);
    } else {
      setSearchTerm("");
    }
  };

  const handleOnChange = async (e) => {
    setIsLoading(true);
    setMenuIsOpen(false);
    const clickedResult = results.find(({ id }) => id === e.value);
    if (clickedResult.type === "Address") {
      setIsLoading(true);
      const { data } = await lookupAddress(clickedResult.id);
      if (data) {
        handleSelection(data);
        setIsLoading(false);
      }
    } else if (e) {
      setIsLoading(true);
      const { data } = await drillDownAddress(clickedResult.id);

      if (data) {
        setOptions(
          data.map((item) => {
            return {
              value: item.id,
              label: createSearchResultLabel(item),
              type: item.type,
            };
          })
        );
        setIsLoading(false);
        setResults(data);
        setMenuIsOpen(true);
      }
      setIsLoading(false);
    }
  };

  const styles = {
    container: (css) => ({ ...css, width: "455px" }),
    control: (css) => ({ ...css, width: "100%", fontSize: "13px" }),
    menu: (css) => ({ ...css, zIndex: 999 }),
  };

  return (
    <>
      <div className={css.addressSearchContainer}>
        <div className={css.addressSearchIcon}>
          <Search />
        </div>
        <div className={css.addressSearchInput}>
          <Select
            inputId={`${id}-address-search-input`}
            placeholder={placeholder}
            classNamePrefix="addressSearch"
            className={css.addressSearchInput}
            isLoading={isLoading}
            label={searchTerm}
            styles={styles}
            filterOption={null}
            menuIsOpen={menuIsOpen}
            components={{
              Option,
              LoadingIndicator: () => <LoadingIndicator />,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={options}
            onInputChange={handleInputChange}
            onChange={handleOnChange}
          />
        </div>
      </div>
      {showValidationMessages && (
        <p className={css.validationError}>
          This address isn't valid. Please check your input and try again.
        </p>
      )}
    </>
  );
};

export default AddressSearch;
