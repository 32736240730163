import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import css from "./index.module.scss";
import { IProduct } from "../../../../../types/select-api/ResponseTypes";
import { formatDateDisplay } from "@lms/react-common-helpers/date";
import {
  configureIcon,
  deleteIcon,
} from "../../../../../components/cdn-image/img";
import CdnImage from "../../../../../components/cdn-image";

interface IProductRowProps {
  product: IProduct;
  hideManageButton?: boolean;
  handleManage: (product: IProduct) => void;
  handleDelete: (product: IProduct) => void;
}

const ProductRow: React.FC<IProductRowProps> = ({
  product,
  hideManageButton,
  handleManage,
  handleDelete,
}) => {
  return (
    <div className={css.container}>
      <div className={css.textField}>{product.name}</div>
      <div className={css.textField}>
        {formatDateDisplay(product.assignedOn)}
      </div>
      <div className={css.textField}></div>
      <div className={css.buttonsContainer}>
        {hideManageButton !== true && (
          <OverlayTrigger
            overlay={<Tooltip id={`manage-${product.id}`}>Manage</Tooltip>}
          >
            <span className={css.iconButtonWrapper}>
              <Button variant="link" onClick={() => handleManage(product)}>
                <CdnImage image={configureIcon} className={css.iconButton} />
              </Button>
            </span>
          </OverlayTrigger>
        )}
        <OverlayTrigger
          overlay={<Tooltip id={`delete-${product.id}`}>Delete</Tooltip>}
        >
          <span className={css.iconButtonWrapper}>
            <Button variant="link" onClick={() => handleDelete(product)}>
              <CdnImage image={deleteIcon} className={css.iconButton} />
            </Button>
          </span>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default ProductRow;
