import {
  STORE_PREFERENCE_DEFAULT_REFERRAL_FEE,
  STORE_PREFERENCE_CONTACT_EMAIL,
  STORE_PREFERENCE_CONTACT_MOBILE,
  STORE_PREFERENCE_DEFAULT_JURISDICTION,
  STORE_PREFERENCE_CASE_UPDATE_TYPE,
  PREFERENCES_VALIDATION_FAILURE,
  SET_PREFERENCES,
  STORE_PREFERENCE_UINSURE_QUOTING_TYPE,
  STORE_PREFERENCE_PREFERRED_PRICING_MODEL,
  STORE_PREFERENCE_LAST_UPDATED,
  STORE_PREFERENCE_NETWORK,
  STORE_UINSURE_LINK_ERROR,
  STORE_UINSURE_VALIDATION,
  UINSURE_LINK_COMPLETE,
} from "../actions/types";
import { jurisdictionsWithNone } from "../../components/shared/data";

const initialState = {
  type: null,
  loaded: { value: false, validation: { message: null } },
  forenames: null,
  surname: null,
  uinsure: {
    uinsureLinked: { value: false, validation: { message: null } },
    quotingType: {
      value: "DoNotIncludeGeneralInsuranceQuote",
      validation: { message: null },
    },
    validation: {
      email: "",
      password: "",
      terms: "",
    },
  },
  defaultReferralFee: { value: 100, validation: { message: null } },
  contactEmail: { value: null, validation: { message: null } },
  contactMobile: { value: null, validation: { message: null } },
  jurisdiction: {
    value: jurisdictionsWithNone.NONE.value,
    validation: { message: null },
  },
  network: { value: null, validation: { message: null } },
  updateType: { value: "WhenCheckingWebsite", validation: { message: null } },
  preferredPricingModel: {
    value: "FixedPrice",
    validation: { message: null },
  },
  lastUpdated: { value: null },
};

function updateObject<T>(oldValue: T, payload: unknown) {
  return {
    ...oldValue,
    value: payload,
    validation: { message: null },
  };
}

export default function (state = initialState, action: any) {
  switch (action.type) {
    case STORE_PREFERENCE_DEFAULT_REFERRAL_FEE:
      return {
        ...state,
        defaultReferralFee: updateObject(
          state.defaultReferralFee,
          action.payload
        ),
      };

    case STORE_PREFERENCE_CONTACT_EMAIL:
      return {
        ...state,
        contactEmail: updateObject(state.contactEmail, action.payload),
      };

    case STORE_PREFERENCE_CONTACT_MOBILE:
      return {
        ...state,
        contactMobile: updateObject(state.contactMobile, action.payload),
      };

    case STORE_PREFERENCE_DEFAULT_JURISDICTION:
      return {
        ...state,
        jurisdiction: updateObject(state.jurisdiction, action.payload),
      };

    case STORE_PREFERENCE_CASE_UPDATE_TYPE:
      return {
        ...state,
        updateType: updateObject(state.updateType, action.payload),
      };

    case STORE_PREFERENCE_PREFERRED_PRICING_MODEL:
      return {
        ...state,
        preferredPricingModel: updateObject(
          state.preferredPricingModel,
          action.payload
        ),
      };

    case STORE_PREFERENCE_UINSURE_QUOTING_TYPE:
      return {
        ...state,
        uinsure: {
          ...state.uinsure,
          quotingType: updateObject(state.uinsure.quotingType, action.payload),
        },
      };

    case STORE_UINSURE_LINK_ERROR:
      return {
        ...state,
        uinsure: {
          ...state.uinsure,
          linkError: action.payload,
        },
      };

    case STORE_UINSURE_VALIDATION:
      return {
        ...state,
        uinsure: {
          ...state.uinsure,
          validation: {
            ...state.uinsure.validation,
            email: action.payload.email,
            password: action.payload.password,
            terms: action.payload.terms,
          },
        },
      };

    case UINSURE_LINK_COMPLETE:
      return {
        ...state,
        uinsure: {
          ...state.uinsure,
          linkComplete: action.payload,
          uinsureLinked: updateObject(
            state.uinsure.uinsureLinked,
            action.payload
          ),
        },
      };

    case STORE_PREFERENCE_LAST_UPDATED:
      return {
        ...state,
        lastUpdated: updateObject(state.lastUpdated, action.payload),
      };

    case STORE_PREFERENCE_NETWORK:
      return {
        ...state,
        network: updateObject(state.network, action.payload),
      };

    case PREFERENCES_VALIDATION_FAILURE:
      return {
        ...state,
        ...action.payload,
      };

    case SET_PREFERENCES:
      return {
        ...state,
        loaded: updateObject(state.type, action.payload.loaded),
        forenames: action.payload.forenames,
        surname: action.payload.surname,
        uinsure: {
          quotingType: updateObject(
            state.type,
            action.payload.uinsurePreferences.quotingType
          ),
          uinsureLinked: updateObject(
            state.type,
            action.payload.uinsurePreferences.uinsureLinked
          ),
          blockedByNetwork: action.payload.uinsurePreferences.blockedByNetwork,
        },
        defaultReferralFee: updateObject(
          state.type,
          action.payload.defaultReferralFee
        ),
        contactEmail: updateObject(state.type, action.payload.emailAddress),
        contactMobile: updateObject(state.type, action.payload.telephoneNumber),
        jurisdiction: updateObject(state.type, action.payload.jurisdiction),
        updateType: updateObject(state.type, action.payload.receiveCaseUpdates),
        preferredPricingModel: updateObject(
          state.type,
          action.payload.preferredPricingModel
        ),
        lastUpdated: updateObject(state.type, action.payload.lastUpdated),
        network: updateObject(state.type, action.payload.network),
      };

    default:
      return state;
  }
}
