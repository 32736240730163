import React, { useEffect, useState } from "react";
import css from "./index.module.scss";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import FloatingLabel from "react-bootstrap-floating-label";
import useSelectApi from "../../../../../hooks/useSelectApi";
import { ProductId } from "../../../../../types/select-api/ResponseTypes";
import { usePermissionContext } from "../../../../../context/permission-context";

interface IProductConditionsProps {
  productId: ProductId;
}

const ProductConditions = ({ productId }: IProductConditionsProps) => {
  const { getProductConditions, saveProductConditions } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: true,
  });
  const { canEditPricing } = usePermissionContext();

  const [lowerPriceLimit, setLowerPriceLimit] = useState<number | null>();
  const [upperPriceLimit, setUpperPriceLimit] = useState<number | null>();
  const [priceErrors, setPriceErrors] = useState<string>("");
  const [limitedCompany, setLimitedCompany] = useState<boolean>(true);
  const [multipleOccupancy, setMultipleOccupancy] = useState<boolean>(true);
  const [highRiseFlat, setHighRiseFlat] = useState<boolean>(true);
  const [nonUKCustomer, setNonUKCustomer] = useState<boolean>(true);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchConditions = async () => {
      setLoading(true);
      const apiResult = await getProductConditions(productId);
      if (apiResult.success) {
        var data = apiResult.data!;
        setLowerPriceLimit(data.lowerLimit);
        setUpperPriceLimit(data.upperLimit);
        setLimitedCompany(data.limitedCompany);
        setMultipleOccupancy(data.multipleOccupancy);
        setHighRiseFlat(data.highRiseFlat);
        setNonUKCustomer(data.nonUkCustomers);
      }
      setLoading(false);
    };

    fetchConditions();
  }, [productId]);

  useEffect(() => {
    ValidLimits();
  }, [lowerPriceLimit, upperPriceLimit]);

  const ValidLimits = (): boolean => {
    if (lowerPriceLimit && upperPriceLimit) {
      if (lowerPriceLimit > upperPriceLimit) {
        setPriceErrors("Lower limit cannot be greater than upper limit.");
        return false;
      }
    }

    setPriceErrors("");
    return true;
  };

  const handleLowerLimitChange = (value: string) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue) && value !== "") {
      return;
    }
    ValidLimits();
    console.log(priceErrors);
    setLowerPriceLimit(parsedValue);
  };

  const handleUpperLimitChange = (value: string) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue) && value !== "") {
      return;
    }
    setUpperPriceLimit(parsedValue);
  };

  const handleSubmit = async () => {
    if (!ValidLimits()) return;

    setSaving(true);
    await saveProductConditions(productId, {
      lowerLimit: lowerPriceLimit,
      upperLimit: upperPriceLimit,
      limitedCompany: limitedCompany,
      highRiseFlat: highRiseFlat,
      nonUkCustomers: nonUKCustomer,
      multipleOccupancy: multipleOccupancy,
    });

    setSaving(false);
  };

  const getLimitClassname = () =>
    canEditPricing ? css.limitInput : css.disabledLimitInput;

  return (
    <Container className={css.container}>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Row className={css.headerRow}>
            <Col>Name</Col>
            <Col>Provide</Col>
          </Row>
          <Row className={css.itemRow}>
            <Col className={css.labelCell}>Price</Col>
            <Col>
              <Container>
                <Row className={css.limitRow}>
                  <Col>
                    <FloatingLabel
                      label="Lower Limit"
                      type="number"
                      className={getLimitClassname()}
                      onChange={(e) =>
                        handleLowerLimitChange(
                          (e.target as HTMLInputElement).value
                        )
                      }
                      initialValue={lowerPriceLimit?.toString() || ""}
                    />
                  </Col>
                  <Col>
                    <FloatingLabel
                      labelId="lower-limit-label"
                      inputId="lower-limit-input"
                      aria-labelledby="lower-limit-label"
                      label="Upper Limit"
                      type="number"
                      className={getLimitClassname()}
                      onChange={(e) =>
                        handleUpperLimitChange(
                          (e.target as HTMLInputElement).value
                        )
                      }
                      initialValue={upperPriceLimit?.toString() || ""}
                    />
                  </Col>
                </Row>
                {priceErrors && (
                  <Row className={css.limitErrorRow}>
                    <Col>{priceErrors}</Col>
                  </Row>
                )}
              </Container>
            </Col>
          </Row>
          <Row className={css.itemRow}>
            <Col className={css.labelCell}>
              <label htmlFor="limitedCompany">Limited Company</label>
            </Col>
            <Col>
              <Form.Check
                id="limitedCompany"
                type="switch"
                checked={limitedCompany}
                onChange={(e) => setLimitedCompany(e.target.checked)}
                disabled={saving || !canEditPricing}
              />
            </Col>
          </Row>
          <Row className={css.itemRow}>
            <Col className={css.labelCell}>
              <label htmlFor="multipleOccupancy">Multiple Occupancy</label>
            </Col>
            <Col>
              <Form.Check
                id="multipleOccupancy"
                type="switch"
                checked={multipleOccupancy}
                onChange={(e) => setMultipleOccupancy(e.target.checked)}
                disabled={saving || !canEditPricing}
              />
            </Col>
          </Row>
          <Row className={css.itemRow}>
            <Col className={css.labelCell}>
              <label htmlFor="highRiseFlat">High Rise Flat</label>
            </Col>
            <Col>
              <Form.Check
                id="highRiseFlat"
                type="switch"
                checked={highRiseFlat}
                onChange={(e) => setHighRiseFlat(e.target.checked)}
                disabled={saving || !canEditPricing}
              />
            </Col>
          </Row>
          <Row className={css.itemRow}>
            <Col className={css.labelCell}>
              <label htmlFor="nonUKCustomer">Non-UK Customer</label>
            </Col>
            <Col>
              <Form.Check
                id="nonUKCustomer"
                type="switch"
                checked={nonUKCustomer}
                onChange={(e) => setNonUKCustomer(e.target.checked)}
                disabled={saving || !canEditPricing}
              />
            </Col>
          </Row>
          {canEditPricing && (
            <Row>
              <Button
                className={css.submitButton}
                variant="primary"
                onClick={handleSubmit}
                disabled={saving || !canEditPricing}
              >
                {saving ? <Spinner animation="border" size="sm" /> : "Submit"}
              </Button>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};
export default ProductConditions;
