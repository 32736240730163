const tempIdPrefix = "temp";

export type TempId = `${typeof tempIdPrefix}${string}`;

export function isTempId(id: string | TempId): id is TempId {
  return id.startsWith(tempIdPrefix);
}

export function generateTempId() {
  return `${tempIdPrefix}${self.crypto.randomUUID()}` as TempId;
}
