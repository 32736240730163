import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";

import AddressSection from "../address-section";
import SummarySection from "../../components/summary-section";
import SectionAccordion from "../sectionAccordion";
import SearchableDropdown from "../../searchable-dropdown";
import LabelledTextInput from "../../../common/components/labelled-text-input";
import LabelledTextArea from "../../../common/components/labelled-textarea";
import CustomerForm from "../../components/customer-form";
import Button from "../../../../components/button/button";
import SummaryDetails from "../../components/summary-details";
import { insuranceChoice, tenures } from "../../../../components/shared/data";

import { Row, Col } from "react-bootstrap";

import formatter from "../../../../utils/formatter";

import * as customerActions from "../../../../redux/actions/instruct-actions/customer";
import * as lenderActions from "../../../../redux/actions/instruct-actions/lender";
import * as insuranceActions from "../../../../redux/actions/instruct-actions/insurance";
import * as additionalInfoActions from "../../../../redux/actions/instruct-actions/additional-info";
import RadioSelector from "../../../../components/selectors/radio/radioselector";

import { UInsureFull } from "../../../../components/shared/imgs";

import css from "../../instructQuote.module.css";
import commonCss from "../../../common/styles.module.css";

const SectionsBlock = ({
  isRelatedQuote,
  title,
  caseTypeIdx,
  addSectionComplete,
  unsetSectionComplete,
  isTest,
  canInstruct,
  instructAction,
  scrollToNextSection = () => {},
}) => {
  const dispatch = useDispatch();

  const insuranceReference = useSelector(
    (store) => store.uinsure?.selectedQuote
  );
  const additionalInfo = useSelector((store) => store.instruct.additionalInfo);
  const savedQuoteType = isRelatedQuote
    ? useSelector(
        (store) =>
          store.instruct.instructProcessState.savedQuote?.data?.relatedQuote
            ?.quoteType ?? ""
      )
    : useSelector(
        (store) =>
          store.instruct.instructProcessState.savedQuote?.data?.quoteType ?? ""
      );

  const lenders = useSelector((store) => store.lenders)?.data;

  const initialState = {
    current: "ADDRESS_SECTION",
    sections: [
      {
        name: "ADDRESS_SECTION",
        complete: false,
        required: true,
        next: "CUSTOMER_SECTION",
      },
      {
        name: "CUSTOMER_SECTION",
        complete: false,
        required: true,
        next: "LENDER_SECTION",
      },
      {
        name: "LENDER_SECTION",
        complete: false,
        required: true,
        next: "ADDITIONAL_INFORMATION",
      },
      {
        name: "ADDITIONAL_INFORMATION",
        complete: false,
        required: true,
        next: "INSURANCE_SECTION",
      },
      {
        name: "INSURANCE_SECTION",
        complete: false,
        required: insuranceReference && savedQuoteType.toLowerCase() !== "sale",
      },
    ],
  };

  const [sectionStates, setSectionStates] = useState(initialState);
  const [additionalInfoValidState, setAdditionalInfoValidState] =
    useState(true);
  const customerAnchor = useRef(null);

  const validateAdditionalInfo = (value) => {
    return !value || (value.length >= 12 && value.length <= 2048);
  };

  const sectionCompletionStates = sectionStates.sections.reduce((states, s) => {
    const newStates = { ...states };
    newStates[s.name] = s.required ? s.complete : true;
    return newStates;
  }, {});

  const setSectionComplete = (section) => {
    setSectionStates({
      ...sectionStates,
      current:
        sectionStates.sections.find((s) => s.name === section).next ||
        "COMPLETE",
      sections: sectionStates.sections.map((s) => {
        if (s.name !== section) return s;

        return {
          ...s,
          complete: true,
        };
      }),
    });
  };

  const setSectionEdit = (section, otherSections) => {
    if (_.every(Object.values(sectionCompletionStates), Boolean))
      unsetSectionComplete();

    setSectionStates({
      ...sectionStates,
      current: section,
      sections: sectionStates.sections.map((s) => {
        if (!otherSections.includes(s.name)) return s;
        return {
          ...s,
          complete: false,
        };
      }),
    });
  };

  const savedPropertyAddress = isRelatedQuote
    ? useSelector(
        (store) => store.instruct?.propertyAddress?.relatedQuote ?? {}
      )
    : useSelector((store) => store.instruct?.propertyAddress ?? {});

  const customers = isRelatedQuote
    ? useSelector((store) => store.instruct.customers?.relatedQuote) ?? []
    : useSelector((store) => store.instruct.customers?.quote ?? []);

  const customerPropUpdate = (idx) => (action) => (newValue) => {
    dispatch(action(idx, newValue, isRelatedQuote));
  };

  const [customerCompletionOutstanding, setCustomerCompletionOutstanding] =
    useState(false);
  const submitCustomersSection = () => {
    if (customers.length < 1) {
      setCustomerCompletionOutstanding(true);
      return;
    }
    const allCustomersSubmitted = customers.reduce(
      (accum, current) => accum && current.submitted,
      true
    );
    if (!allCustomersSubmitted) {
      setCustomerCompletionOutstanding(true);
      return;
    }

    setSectionComplete("CUSTOMER_SECTION");

    setCustomerCompletionOutstanding(false);
  };

  const lenderState = useSelector((store) => store.instruct.lender);
  const insuranceState = useSelector(
    (store) => store.instruct.insurance?.processType?.value
  );
  const currentStoreState = useStore();
  const submitLenderSection = () => {
    dispatch(lenderActions.VALIDATE_LENDER_INFO.action(savedQuoteType));

    if (
      Object.keys(currentStoreState.getState().instruct.lender.validationErrors)
        .length === 0
    ) {
      setSectionComplete("LENDER_SECTION");
      if (!insuranceReference) scrollToNextSection();
    }
  };

  const submitInsuranceSection = () => {
    dispatch(insuranceActions.VALIDATE_INSURANCE_PREFERENCE.action());

    if (
      !currentStoreState.getState().instruct.insurance?.processType
        ?.validationErrors
    ) {
      setSectionComplete("INSURANCE_SECTION");
      scrollToNextSection();
    }
  };

  const submitAdditionalInfoSection = () => {
    const valueToValidate = isRelatedQuote
      ? additionalInfo?.relatedQuote?.notes
      : additionalInfo?.notes;

    if (validateAdditionalInfo(valueToValidate)) {
      setAdditionalInfoValidState(true);
      setSectionComplete("ADDITIONAL_INFORMATION");
    } else {
      setAdditionalInfoValidState(false);
    }
  };

  useEffect(() => {
    if (_.every(Object.values(sectionCompletionStates), Boolean))
      addSectionComplete();
  }, [sectionStates]);

  /* Summary section */
  const unsubmitCustomerFromSummary = (idx) => {
    setSectionEdit("CUSTOMER_SECTION", [
      "CUSTOMER_SECTION",
      "LENDER_SECTION",
      "ADDITIONAL_INFORMATION",
      "INSURANCE_SECTION",
    ]);
    dispatch(customerActions.UNSUBMIT_CUSTOMER.action(idx, "", isRelatedQuote));
  };

  const unsubmitAdditionalInfoFromSummary = () => {
    setSectionEdit("ADDITIONAL_INFORMATION", [
      "ADDITIONAL_INFORMATION",
      "INSURANCE_SECTION",
    ]);
  };

  const extractAddressLabel = (address) => {
    if (!address) return "";
    let addressLabel;
    addressLabel = [
      `${(address.houseName ? address.houseName : address.houseNumber) || ""} ${
        address.streetName || ""
      }`,
      address.districtName,
      address.postTown,
      address.county,
      address.postCode,
      address.country,
    ]
      .filter((i) => !!i)
      .join(", ");

    return addressLabel;
  };

  const propertyAddress = useSelector((store) =>
    isRelatedQuote
      ? extractAddressLabel(store.instruct.propertyAddress?.relatedQuote)
      : extractAddressLabel(store.instruct.propertyAddress?.quote)
  );

  const getTenuresLabel = (value) => {
    if (value) {
      if (isNaN(value)) {
        return value;
      } else {
        return tenures.find((item) => item.value === value).name;
      }
    }
    return null;
  };

  //the OR statements here should not need to exists if there was consistency in names from the API up and down contracts.
  const { jurisdiction, lawFirmName, tenure, propertyPrice, mortgageInvolved } =
    useSelector((store) => {
      return {
        jurisdiction: isRelatedQuote
          ? store.instruct.instructProcessState.savedQuote?.data?.relatedQuote
              ?.jurisdiction
          : store.instruct.instructProcessState.savedQuote?.data.jurisdiction,
        lawFirmName: isRelatedQuote
          ? store.instruct.instructProcessState.savedQuote?.data?.relatedQuote
              ?.name ||
            store.instruct.instructProcessState.savedQuote?.data?.relatedQuote
              ?.lawFirmName
          : store.instruct.instructProcessState.savedQuote?.data.lawFirmName ||
            store.instruct.instructProcessState.savedQuote?.data.name,
        tenure: isRelatedQuote
          ? getTenuresLabel(
              store.instruct.instructProcessState.savedQuote?.data?.relatedQuote
                ?.tenure
            ) ||
            getTenuresLabel(
              store.instruct.instructProcessState.savedQuote?.data?.relatedQuote
                ?.Tenure
            )
          : getTenuresLabel(
              store.instruct.instructProcessState.savedQuote?.data.tenure
            ) ||
            getTenuresLabel(
              store.instruct.instructProcessState.savedQuote?.data.Tenure
            ),
        propertyPrice: formatter.formatCurrency(
          isRelatedQuote
            ? store.instruct.instructProcessState.savedQuote?.data?.relatedQuote
                ?.propertyPrice ||
                store.instruct.instructProcessState.savedQuote?.data
                  ?.relatedQuote?.TransactionAmount
            : store.instruct.instructProcessState.savedQuote?.data
                .propertyPrice ||
                store.instruct.instructProcessState.savedQuote?.data
                  .TransactionAmount ||
                store.instruct.instructProcessState.savedQuote?.data
                  .transactionAmount
        ),
        mortgageInvolved: formatter.formatBoolean(
          isRelatedQuote
            ? store.instruct.instructProcessState.savedQuote?.data?.relatedQuote
                ?.mortgageInvolved
            : store.instruct.instructProcessState.savedQuote?.data
                .mortgageInvolved
        ),
      };
    });

  return (
    <>
      <Col md={8} lg={8} className={css.formColumn}>
        <Row className={css.sectionRow}>
          {title && <h2 className={commonCss.sectionHeading}>{title}</h2>}
          <SectionAccordion
            heading="MORE ABOUT THE PROPERTY"
            show={isTest ? true : !sectionCompletionStates.ADDRESS_SECTION}
            completed={sectionCompletionStates.ADDRESS_SECTION}
            completedIconClassName={css.propertyCompletedIcon}
          >
            <AddressSection
              completeCallback={() => setSectionComplete("ADDRESS_SECTION")}
              value={savedPropertyAddress}
              caseTypeIdx={caseTypeIdx}
              isRelatedQuote={isRelatedQuote}
            />
          </SectionAccordion>
        </Row>
        <Row className={css.sectionRow} ref={customerAnchor}>
          <SectionAccordion
            heading="MORE ABOUT THE CUSTOMER"
            show={
              isTest
                ? true
                : sectionCompletionStates?.ADDRESS_SECTION &&
                  !sectionCompletionStates?.CUSTOMER_SECTION
            }
            completed={sectionCompletionStates.CUSTOMER_SECTION}
            completedIconClassName={css.customerCompletedIcon}
          >
            {customers?.map((c, customerIdx) => {
              if (!c.submitted) {
                const doCustomerPropUpdate = customerPropUpdate(
                  customerIdx,
                  isRelatedQuote
                );
                return (
                  <React.Fragment key={customerIdx}>
                    <CustomerForm
                      customer={customers[customerIdx]}
                      id={`customer${customerIdx}`}
                      key={customerIdx}
                      idx={customerIdx}
                      caseTypeIdx={caseTypeIdx}
                      onTitleChange={doCustomerPropUpdate(
                        customerActions.UPDATE_TITLE.action
                      )}
                      onFirstNameChange={doCustomerPropUpdate(
                        customerActions.UPDATE_FIRST_NAME.action
                      )}
                      onSurnameChange={doCustomerPropUpdate(
                        customerActions.UPDATE_SURNAME.action
                      )}
                      onAddressChange={doCustomerPropUpdate(
                        customerActions.UPDATE_ADDRESS.action
                      )}
                      onAutomaticAddressChange={doCustomerPropUpdate(
                        customerActions.UPDATE_AUTOMATIC_ADDRESS.action
                      )}
                      onAutomaticAddressLabelChange={doCustomerPropUpdate(
                        customerActions.UPDATE_AUTOMATIC_ADDRESS_LABEL.action
                      )}
                      onManualHouseNameChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MANUAL_HOUSE_NAME.action
                      )}
                      onManualHouseNumberChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MANUAL_HOUSE_NUMBER.action
                      )}
                      onManualStreetNameChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MANUAL_STREET_NAME.action
                      )}
                      onManualDistrictChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MANUAL_DISTRICT.action
                      )}
                      onManualPostTownChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MANUAL_POST_TOWN.action
                      )}
                      onManualPostcodeChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MANUAL_POSTCODE.action
                      )}
                      onManualCountyChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MANUAL_COUNTY.action
                      )}
                      onManualCountryChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MANUAL_COUNTRY.action
                      )}
                      onUsedManualAddressEntryChange={doCustomerPropUpdate(
                        customerActions.UPDATE_USED_MANUAL_ADDRESS_ENTRY.action
                      )}
                      onEmailChange={doCustomerPropUpdate(
                        customerActions.UPDATE_EMAIL.action
                      )}
                      onMobileCallingCodeChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MOBILE_NUMBER_CALLING_CODE.action
                      )}
                      onMobileChange={doCustomerPropUpdate(
                        customerActions.UPDATE_MOBILE_NUMBER.action
                      )}
                      onWorkPhoneNumberCallingCodeChange={doCustomerPropUpdate(
                        customerActions.UPDATE_WORK_NUMBER_CALLING_CODE.action
                      )}
                      onWorkPhoneNumberChange={doCustomerPropUpdate(
                        customerActions.UPDATE_WORK_NUMBER.action
                      )}
                      onHomePhoneNumberCallingCodeChange={doCustomerPropUpdate(
                        customerActions.UPDATE_HOME_NUMBER_CALLING_CODE.action
                      )}
                      onHomePhoneNumberChange={doCustomerPropUpdate(
                        customerActions.UPDATE_HOME_NUMBER.action
                      )}
                      onComplete={() => {
                        doCustomerPropUpdate(
                          customerActions.SUBMIT_CUSTOMER.action
                        )();
                        setCustomerCompletionOutstanding(false);
                        customerAnchor.current.scrollIntoView();
                      }}
                      canRemoveCustomer={customers.length > 1}
                      onRemoveCustomer={() =>
                        dispatch(
                          customerActions.REMOVE_CUSTOMER.action(
                            customerIdx,
                            "",
                            isRelatedQuote
                          )
                        )
                      }
                      errors={customers[customerIdx].errors}
                    />
                  </React.Fragment>
                );
              } else {
                return (
                  <div className={css.submittedCustomer} key={customerIdx}>
                    <div>{`${c.firstName} ${c.surname}`}</div>
                    <div>
                      <button
                        className={css.submittedCustomerButton}
                        onClick={() =>
                          dispatch(
                            customerActions.UNSUBMIT_CUSTOMER.action(
                              customerIdx,
                              "",
                              isRelatedQuote
                            )
                          )
                        }
                      >
                        Edit
                      </button>
                      <button
                        className={css.submittedCustomerButton}
                        onClick={() =>
                          dispatch(
                            customerActions.REMOVE_CUSTOMER.action(
                              customerIdx,
                              "",
                              isRelatedQuote
                            )
                          )
                        }
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                );
              }
            })}
            {customerCompletionOutstanding && (
              <div className={css.error}>
                * You must have at least one customer and they must all be added
                before you may continue
              </div>
            )}
            <div
              className={`${commonCss.buttonContainer} ${css.customerSectionButtonContainer}`}
            >
              {customers?.length < 4 && (
                <Button
                  id="InstructAddCustomer"
                  datatestid="InstructAddCustomer"
                  btnClass={`${commonCss.buttonMd} ${css.addNewCustomerButton}`}
                  onClick={() =>
                    dispatch(
                      customerActions.ADD_NEW_CUSTOMER.action(
                        null,
                        isRelatedQuote
                      )
                    )
                  }
                  text="ADD ANOTHER CUSTOMER"
                />
              )}

              <Button
                id="InstructNext"
                datatestid="InstructNext"
                btnClass={commonCss.buttonMd}
                onClick={submitCustomersSection}
                text="NEXT SECTION"
              />
            </div>
          </SectionAccordion>
        </Row>
        <Row className={css.sectionRow}>
          <SectionAccordion
            heading="A BIT ABOUT THE LENDER"
            show={
              isTest
                ? true
                : sectionCompletionStates?.ADDRESS_SECTION &&
                  sectionCompletionStates?.CUSTOMER_SECTION &&
                  !sectionCompletionStates?.LENDER_SECTION
            }
            completed={sectionCompletionStates?.LENDER_SECTION}
            completedIconClassName={css.lenderCompletedIcon}
          >
            <div className={css.lenderContainer}>
              <div className={css.lenderContent}>
                Are you able to give a bit of information about the lender?
              </div>
              {savedQuoteType.toLowerCase() !== "purchase" && (
                <>
                  <div>
                    <SearchableDropdown
                      disabled={savedQuoteType.toLowerCase() !== "remortgage"}
                      id={`existingLenderDropdown${caseTypeIdx}`}
                      label="Lender of existing mortgage (Optional)"
                      placeholder={
                        savedQuoteType.toLowerCase() !== "remortgage"
                          ? "None"
                          : "Please select the existing lender..."
                      }
                      value={
                        isRelatedQuote
                          ? lenderState?.relatedQuote?.existingLender
                          : lenderState?.existingLender
                      }
                      onChange={(newExistingLender) =>
                        dispatch(
                          lenderActions.UPDATE_EXISTING_LENDER.action(
                            newExistingLender,
                            isRelatedQuote
                          )
                        )
                      }
                      list={lenders}
                      menuClassName={css.lenderDropDown}
                    />
                  </div>
                  <div>
                    <LabelledTextInput
                      label="Existing mortgage account number (Optional)"
                      placeholder="Enter your mortgage account number"
                      id={`existingMortgageAccountNumber${caseTypeIdx}`}
                      value={
                        isRelatedQuote
                          ? lenderState?.relatedQuote?.existingMortgageNumber
                          : lenderState?.existingMortgageNumber
                      }
                      onChange={(newExistingMortgageNumber) =>
                        dispatch(
                          lenderActions.UPDATE_EXISTING_MORTGAGE_NUMBER.action(
                            newExistingMortgageNumber,
                            isRelatedQuote
                          )
                        )
                      }
                      type="text"
                      errorMessages={
                        lenderState.validationErrors?.existingMortgageNumber
                      }
                    />
                  </div>
                </>
              )}
              {savedQuoteType.toLowerCase() !== "sale" && (
                <div>
                  <SearchableDropdown
                    disabled={true}
                    id={`newLenderDropdown${caseTypeIdx}`}
                    label="Who is the lender providing the new mortgage (Optional)"
                    placeholder="None"
                    value={
                      isRelatedQuote
                        ? lenderState.relatedQuote?.newLender
                        : lenderState.newLender
                    }
                    onChange={(newLender) =>
                      dispatch(
                        lenderActions.UPDATE_NEW_LENDER.action(
                          newLender,
                          isRelatedQuote
                        )
                      )
                    }
                    list={lenders}
                    menuClassName={css.lenderDropDown}
                  />
                </div>
              )}
              <div className={commonCss.buttonContainer}>
                <Button
                  id={`InstructComplete${caseTypeIdx}`}
                  datatestid="InstructComplete"
                  btnClass={commonCss.buttonMd}
                  onClick={submitLenderSection}
                  text="NEXT SECTION"
                />
              </div>
            </div>
          </SectionAccordion>
        </Row>
        <Row className={css.sectionRow}>
          <SectionAccordion
            heading="ANY ADDITIONAL INFORMATION"
            show={
              isTest
                ? true
                : sectionCompletionStates?.LENDER_SECTION &&
                  !sectionCompletionStates?.ADDITIONAL_INFORMATION
            }
            completed={sectionCompletionStates?.ADDITIONAL_INFORMATION}
            completedIconClassName={css.additionInformationCompletedIcon}
          >
            <div className={css.lenderContainer}>
              <div className={css.lenderContent}>
                Is there any extra information you would like to give the law
                firm?
              </div>

              <div>
                <LabelledTextArea
                  label="Notes for law firm"
                  placeholder="Enter any notes for the law firm"
                  id={`lawFirmNotes${caseTypeIdx}`}
                  value={
                    isRelatedQuote
                      ? additionalInfo?.relatedQuote?.notes
                      : additionalInfo?.notes
                  }
                  onChange={(additionalInfo) =>
                    dispatch(
                      additionalInfoActions.UPDATE_ADDITIONAL_INFO.action(
                        additionalInfo,
                        isRelatedQuote
                      )
                    )
                  }
                  type="text"
                  errorMessages={
                    additionalInfoValidState
                      ? []
                      : ["Notes must be between 12 and 2048 characters"]
                  }
                />
              </div>

              <div className={commonCss.buttonContainer}>
                <Button
                  id={`InstructComplete${caseTypeIdx}`}
                  datatestid="InstructComplete"
                  btnClass={commonCss.buttonMd}
                  onClick={submitAdditionalInfoSection}
                  text="COMPLETE SECTION"
                />
              </div>
            </div>
          </SectionAccordion>
        </Row>
        {insuranceReference && savedQuoteType.toLowerCase() !== "sale" && (
          <Row className={css.sectionRow}>
            <SectionAccordion
              heading="MORE ABOUT HOME INSURANCE"
              show={
                isTest
                  ? true
                  : sectionCompletionStates?.ADDRESS_SECTION &&
                    sectionCompletionStates?.CUSTOMER_SECTION &&
                    sectionCompletionStates?.LENDER_SECTION &&
                    sectionCompletionStates?.ADDITIONAL_INFORMATION &&
                    !sectionCompletionStates?.INSURANCE_SECTION
              }
              completed={sectionCompletionStates?.INSURANCE_SECTION}
              completedIconClassName={css.insuranceCompletedIcon}
            >
              <div className={css.lenderContainer}>
                <Row>
                  <UInsureFull className={css.uinsureimage} />
                </Row>
                <Row className={css.lenderContent}>
                  <RadioSelector
                    id={"insurance-choice"}
                    datatestid={"insurance-choice"}
                    persistaction={(value) =>
                      insuranceActions.UPDATE_INSURANCE_PREFERENCE.action(value)
                    }
                    storelocation={(store) =>
                      store.instruct?.insurance?.processType?.value
                    }
                    name={"insurance-choice"}
                    radios={insuranceChoice}
                    errorMessage={(store) =>
                      store.instruct?.insurance?.processType?.validationErrors
                    }
                  />
                </Row>
                <div className={commonCss.buttonContainer}>
                  <Button
                    id={`InstructComplete${caseTypeIdx}`}
                    datatestid="InstructComplete"
                    btnClass={commonCss.buttonMd}
                    onClick={submitInsuranceSection}
                    text="COMPLETE SECTION"
                  />
                </div>
              </div>
            </SectionAccordion>
          </Row>
        )}
        {canInstruct && (
          <Row>
            <Col className={css.submitControls}>
              <div>
                <h1
                  className={`${commonCss.pageHeading} ${css.readyToSubmitMessageHeading}`}
                >
                  WE NOW HAVE ALL THE DETAILS WE NEED
                </h1>
                <div>
                  Please take some time to review the case summary before
                  instructing the case.
                </div>
              </div>

              <div className={css.saveButtonContainer}>
                <Button
                  id="InstructButton"
                  datatestid="InstructButton"
                  btnClass={commonCss.button}
                  onClick={instructAction}
                  text="Review Terms & conditions"
                />
              </div>
            </Col>
          </Row>
        )}
      </Col>
      <Col md={3} lg={3} className={css.summaryColumn}>
        <div className={css.summaryContainer}>
          <h2 className={css.summaryHeading}>SUMMARY</h2>
          <SummarySection
            heading="CASE DETAILS"
            show={true}
            completed={true}
            completedIconClassName={css.caseDetailsCompleted}
          >
            <SummaryDetails title="Region:" value={jurisdiction} />
            <SummaryDetails title="Law Firm:" value={lawFirmName} />
          </SummarySection>
          <SummarySection
            heading="PROPERTY DETAILS"
            show={isTest ? true : sectionCompletionStates?.ADDRESS_SECTION}
            completed={sectionCompletionStates?.ADDRESS_SECTION}
            completedIconClassName={css.propertyCompletedIcon}
          >
            <SummaryDetails title="Tenure:" value={tenure} />
            <SummaryDetails title="Price:" value={propertyPrice} />
            <SummaryDetails title="Lender Involved:" value={mortgageInvolved} />
            <SummaryDetails title="Conveyed address:" value={propertyAddress} />
            <div>
              <Button
                id="InstructEdit"
                datatestid="InstructEdit"
                btnClass={css.summaryEdit}
                onClick={() =>
                  setSectionEdit("ADDRESS_SECTION", [
                    "ADDRESS_SECTION",
                    "CUSTOMER_SECTION",
                    "LENDER_SECTION",
                    "ADDITIONAL_INFORMATION",
                    "INSURANCE_SECTION",
                  ])
                }
                text="Edit"
              />
            </div>
          </SummarySection>
          <SummarySection
            heading="CUSTOMER DETAILS"
            show={isTest ? true : sectionCompletionStates?.ADDRESS_SECTION}
            completed={sectionCompletionStates?.CUSTOMER_SECTION}
            completedIconClassName={css.customerCompletedIcon}
          >
            {customers
              .map((c, idx) => ({ ...c, idx }))
              .filter((c) => c.submitted)
              .map((c) => (
                <React.Fragment key={`customer-${c.idx}`}>
                  <SummaryDetails
                    title={`Customer ${c.idx + 1}:`}
                    value={`${c.firstName} ${c.surname}`}
                  />
                  <SummaryDetails
                    title="Correspondence Address:"
                    value={extractAddressLabel(c.correspondenceAddress)}
                  />
                  <SummaryDetails title="Email:" value={c.email} />
                  <SummaryDetails title="Mobile:" value={c.mobile} />
                  <SummaryDetails
                    title="Work phone:"
                    value={c.workPhoneNumber}
                  />
                  <SummaryDetails
                    title="Home phone:"
                    value={c.homePhoneNumber}
                  />
                  <button
                    className={`${css.summaryEdit} ${css.summaryCustomerEditButton}`}
                    onClick={() => unsubmitCustomerFromSummary(c.idx)}
                  >
                    Edit
                  </button>
                </React.Fragment>
              ))}
          </SummarySection>
          <SummarySection
            heading="LENDER DETAILS"
            show={
              isTest
                ? true
                : sectionCompletionStates?.ADDRESS_SECTION &&
                  sectionCompletionStates?.CUSTOMER_SECTION &&
                  sectionCompletionStates?.LENDER_SECTION
            }
            completed={sectionCompletionStates?.LENDER_SECTION}
            completedIconClassName={css.lenderCompletedIcon}
          >
            {savedQuoteType.toLowerCase() !== "sale" && (
              <SummaryDetails
                title="New lender:"
                value={
                  lenders.find((l) => l.value === lenderState.newLender)?.name
                }
              />
            )}
            {savedQuoteType.toLowerCase() !== "purchase" && (
              <>
                <SummaryDetails
                  title="Existing lender:"
                  value={
                    lenders.find((l) => l.value === lenderState.existingLender)
                      ?.name
                  }
                />
                <SummaryDetails
                  title="Account number:"
                  value={lenderState.existingMortgageNumber}
                />
              </>
            )}
          </SummarySection>
          <SummarySection
            heading="ADDITIONAL INFORMATION"
            show={
              isTest
                ? true
                : sectionCompletionStates?.ADDRESS_SECTION &&
                  sectionCompletionStates?.CUSTOMER_SECTION &&
                  sectionCompletionStates?.LENDER_SECTION &&
                  sectionCompletionStates?.ADDITIONAL_INFORMATION
            }
            completed={sectionCompletionStates?.ADDITIONAL_INFORMATION}
            completedIconClassName={css.additionInformationCompletedIcon}
          >
            {(isRelatedQuote
              ? additionalInfo?.relatedQuote?.notes
              : additionalInfo?.notes) && (
              <SummaryDetails
                title="Notes:"
                value={
                  isRelatedQuote
                    ? additionalInfo?.relatedQuote?.notes
                    : additionalInfo?.notes
                }
              />
            )}
            <button
              className={`${css.summaryEdit} ${css.summaryCustomerEditButton}`}
              onClick={() => unsubmitAdditionalInfoFromSummary()}
            >
              Edit
            </button>
          </SummarySection>
          {insuranceReference && savedQuoteType.toLowerCase() !== "sale" && (
            <SummarySection
              heading="HOME INSURANCE"
              show={
                isTest
                  ? true
                  : sectionCompletionStates?.ADDRESS_SECTION &&
                    sectionCompletionStates?.CUSTOMER_SECTION &&
                    sectionCompletionStates?.LENDER_SECTION &&
                    sectionCompletionStates?.ADDITIONAL_INFORMATION
              }
              completed={sectionCompletionStates?.INSURANCE_SECTION}
              completedIconClassName={css.insuranceCompletedIcon}
            >
              <SummaryDetails
                value={
                  insuranceChoice.find((o) => o.value === insuranceState)?.name
                }
              />
            </SummarySection>
          )}
        </div>
      </Col>
    </>
  );
};

SectionsBlock.propTypes = {
  data: PropTypes.object,
  isRelatedQuote: PropTypes.bool,
  title: PropTypes.string,
  caseTypeIdx: PropTypes.number,
  addSectionComplete: PropTypes.func,
  unsetSectionComplete: PropTypes.func,
  isTest: PropTypes.bool,
  scrollToNextSection: PropTypes.func,
};

export default SectionsBlock;
