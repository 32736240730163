import React, {ReactNode} from "react";
import { AuthService } from "../services/authService/AuthService";
import PropTypes from "prop-types";
import type { User } from "oidc-client";

type AuthContextType = {
  signinRedirectCallback: () => void;
  logout: () => void;
  getUser: () => Promise<User | null>;
  signoutRedirectCallback: () => void;
  isAuthenticated: () => Promise<boolean>;
  signinRedirect: () => void;
  signinSilentCallback: () => void;
  getAccessToken: () => Promise<string | null>;
  onSilentRenewError: (fn: (error: Error) => void) => void;
  persistRequestedUri: () => void;
  redirectToRequestedUri: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  getUser: async () => null,
  signoutRedirectCallback: () => ({}),
  isAuthenticated: async () => false,
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  getAccessToken: async () => null,
  onSilentRenewError: () => ({}),
  persistRequestedUri: () => ({}),
  redirectToRequestedUri: () => ({}),
});

export const AuthConsumer = AuthContext.Consumer;

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const authService = new AuthService(
    window.config.baseUrl,
    window.config.oidc
  ) satisfies AuthContextType;

  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any,
};

export default AuthProvider;
