import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../utils/authProvider";
import { ensureArray } from "@lms/react-common-helpers/arrays";
import UserRole from "../enum/UserRole";

interface IPermissionContext {
  canEditPricing: boolean;
}

const permissions: IPermissionContext = {
  canEditPricing: false,
};

interface IPermissionProviderProps {
  children: ReactNode;
}

const PermissionContext = createContext<IPermissionContext>({
  ...permissions,
});

export const usePermissionContext = (): IPermissionContext => useContext(PermissionContext);

const PermissionProvider = ({ children }: IPermissionProviderProps) => {
  const { getUser } = useContext(AuthContext);

  const [canEditPricing, setCanEditPricing] = useState<boolean>(false);

  const hasRole = (roles: Array<string>, roleToCheck: string): boolean =>
     roles.find((role) => role === roleToCheck) ? true : false;

  useEffect(() => {
    async function init() {
      const user = await getUser();
      const roles = ensureArray(user?.profile?.roles);
      setCanEditPricing(hasRole(roles, UserRole.LMS_SELECT_UPDATE_FEES_ADMIN) && hasRole(roles, UserRole.LAW_FIRM_USER));
    }

    init();
  }, [getUser]);

  const permissions: IPermissionContext = {
    canEditPricing,
  };

  return (
    <PermissionContext.Provider value={permissions}>
      {children}
    </PermissionContext.Provider>
  );
}

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PermissionProvider;