import React, { useEffect, useState } from "react";
import css from "./index.module.scss";
import { Alert } from "react-bootstrap";
import useSelectApi from "../../../../../hooks/useSelectApi";
import {
  ICollapsibleTableRow,
  ICollapsibleTableRowData,
} from "../collapsible-table/types";
import { arrayHasItems } from "@lms/react-common-helpers/arrays";
import { isStringValidCurrency } from "@lms/react-common-helpers/currency";
import CollapsibleTable from "../collapsible-table";
import Loading from "../../../../../components/loading";
import ProductFeeBands from "./product-fee-bands";
import { useNotifications } from "../../../../../hooks/notifications";
import * as notifications from "../../../../../data/notifications";
import FeeRow, { IFeeRow } from "./fee-row";
import {
  FeeId,
  IProductFee,
  PanelId,
  ProductId,
  ProviderId,
} from "../../../../../types/select-api/ResponseTypes";

interface IProductFeesProps {
  officeId?: ProviderId;
  panelId?: PanelId;
  productId: ProductId;
}

const ProductFees = ({ officeId, productId, panelId }: IProductFeesProps) => {
  const {
    getProductFees,
    getOfficeProductFees,
    saveProductFee,
    deleteProductFee,
    saveOfficeProductFee,
    deleteOfficeProductFee,
  } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: true,
  });

  const [fees, setFees] = useState<IFeeRow[]>([]);
  const [loading, setLoading] = useState(true);
  const { showSuccess, showError } = useNotifications();

  const fetchFees = async () => {
    setLoading(true);
    const apiResult = officeId
      ? await getOfficeProductFees(officeId, productId, panelId)
      : await getProductFees(productId);

    if (apiResult.success) {
      var data = apiResult.data!;

      const rowData = data.map((productFee) => {
        return {
          feeId: productFee.id,
          name: productFee.name,
          category: productFee.type,
          pricingType: productFee.overrideLevel ?? "Default",
          netPrice: productFee.netPrice.toFixed(2),
          netPriceOverride: productFee.netPriceOverride?.toFixed(2),
          netPriceValidationError: false,
          isDirty: false,
          disableReset: shouldDisableReset(productFee),
        } satisfies IFeeRow;
      });

      setFees(rowData);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFees();
  }, [productId, panelId]);

  const handleFeeValueChange = (feeId: string, value: string) => {
    setFees((prev) =>
      prev.map((rowData) =>
        rowData.feeId === feeId
          ? {
              ...rowData,
              netPriceOverride: value,
              netPriceValidationError: !isStringValidCurrency(value),
              isDirty: true,
            }
          : rowData
      )
    );
  };

  const handleFeeReset = async (feeId: FeeId) => {
    const fee = fees.find((f) => f.feeId === feeId);

    if (fee?.isDirty) {
      setFees((prev) =>
        prev.map((rowData) =>
          rowData.feeId === feeId
            ? {
                ...rowData,
                netPriceOverride: rowData.netPrice,
                netPriceValidationError: false,
                isDirty: false,
              }
            : rowData
        )
      );
      return;
    }

    // only call backend reset if fee isn't dirty
    const deleteResult = officeId
      ? await deleteOfficeProductFee(officeId, productId, feeId, panelId)
      : await deleteProductFee(productId, feeId);

    if (deleteResult.success) {
      showSuccess(notifications.deleteProductFee.positive);
      fetchFees();
    } else {
      showError(notifications.deleteProductFee.negative);
    }
  };

  const handleSave = async (fee: IFeeRow) => {
    //DOUBLE CHECK FOR SAFETY, INCASE SAVE DISABLED IS BROKEN
    if (!isStringValidCurrency(fee.netPriceOverride || '')) return;

    const requestBody = {
      netPriceOverride: parseFloat(fee.netPriceOverride!),
    };

    const saveResult = officeId
      ? await saveOfficeProductFee(
          officeId,
          productId,
          fee.feeId,
          panelId,
          requestBody
        )
      : await saveProductFee(productId, fee.feeId, requestBody);

    if (saveResult.success) {
      showSuccess(notifications.saveProductFee.positive);
      const updatedFee = saveResult.data;
      setFees((prev) =>
        prev.map((rowData) =>
          rowData.feeId === fee.feeId
            ? {
                ...rowData,
                pricingType: updatedFee.overrideLevel ?? "Default",
                disableReset: shouldDisableReset(updatedFee),
                netPriceValidationError: false,
                isDirty: false,
              }
            : rowData
        )
      );
    } else {
      showError(notifications.saveProductFee.negative);
    }
  };

  const shouldDisableReset = (fee: IProductFee) => {
    if (!fee.overrideLevel) return true;

    if (officeId && fee.overrideLevel === "Custom (Law Firm)") return true;

    if (
      panelId &&
      (fee.overrideLevel === "Custom (Law Firm)" ||
        fee.overrideLevel === "Custom (Office)")
    )
      return true;

    return false;
  };

  const createRow = (
    rowData: IFeeRow,
    index: number
  ): ICollapsibleTableRowData => {
    const row: ICollapsibleTableRow = {
      index: index,
      row: (
        <FeeRow
          feeRow={rowData}
          handleFeeValueChange={handleFeeValueChange}
          handleSave={handleSave}
          handleFeeReset={handleFeeReset}
        />
      ),
    };

    const collapsibleSection = (
      <ProductFeeBands
        officeId={officeId}
        productId={productId}
        feeId={rowData.feeId}
        panelId={panelId}
      />
    );

    return {
      row,
      collapsibleSection,
    };
  };

  const rows: ICollapsibleTableRowData[] = fees.map((row, index) =>
    createRow(row, index)
  );

  const HeaderRow: React.FC = () => {
    return (
      <div className={css.headerRowContainer}>
        <div>FEE</div>
        <div>CATEGORY</div>
        <div>PRICING TYPE</div>
        <div>AMOUNT</div>
        <div></div>
      </div>
    );
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          {arrayHasItems(fees) ? (
            <CollapsibleTable headerRow={<HeaderRow />} rows={rows} />
          ) : (
            <Alert variant="info">
              No overrideable fees exist for this product.
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default ProductFees;
