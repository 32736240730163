import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { arrayHasItems } from "@lms/react-common-helpers/arrays";
import CollapsibleTable from "./collapsible-table";
import {
  ICollapsibleTableRow,
  ICollapsibleTableRowData,
} from "./collapsible-table/types";
import ProductConditions from "./product-conditions";
import DeleteProductModal from "./delete-product-modal";
import ManageProductModal from "./manage-product-modal";
import useSelectApi from "../../../../hooks/useSelectApi";
import {
  IOffice,
  IProduct,
  PanelId,
  ProductId,
} from "../../../../types/select-api/ResponseTypes";
import { useNotifications } from "../../../../hooks/notifications";
import * as notifications from "../../../../data/notifications";
import { IOfficeProvider } from "./manage-product-modal/types";
import ProductFees from "./product-fees";
import ProductRow from "./product-row";
import css from "./index.module.scss";

type ProviderProductsProps = OfficeProductsProps | LawFirmProductsProps;

type OfficeProductsProps = {
  providerType: "office";
  products: IProduct[];
  office: IOffice;
  panelId?: PanelId;
};

type LawFirmProductsProps = {
  providerType: "lawfirm";
  products: IProduct[];
  offices: IOffice[];
};

const ProviderProducts = (props: ProviderProductsProps) => {
  const { deleteProduct, deleteOfficeProduct } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: true,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const [providedProducts, setProvidedProducts] = useState<Array<IProduct>>([]);
  const { showSuccess } = useNotifications();

  useEffect(() => {
    setProvidedProducts(props.products.filter((x) => x.assignedOn != null));
  }, [props.products]);

  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);

  const [selectedProductOffices, setSelectedProductOffices] = useState<
    Array<IOfficeProvider>
  >([]);

  const handleDeleteClick = (product: IProduct) => {
    setSelectedProduct(product);
    setShowDeleteModal(true);
  };

  const handleManageClick = (product: IProduct) => {
    if (props.providerType !== "lawfirm") {
      return;
    }

    setSelectedProduct(product);
    setSelectedProductOffices(
      props.offices.map((x) => ({
        id: x.id,
        name: x.name,
        hasProductAssigned: product.assignedOfficeIds.indexOf(x.id) > -1,
      }))
    );
    setShowManageModal(true);
  };

  const handleDeleteModalClose = (success: boolean) => {
    setShowDeleteModal(false);
    setSelectedProduct(null);

    if (success) {
      showSuccess(notifications.deleteProduct.positive);
    }
  };

  const handleProductDelete = (productId: ProductId) => async () => {
    setShowDeleteModal(false);
    setSelectedProduct(null);

    const result =
      props.providerType === "office"
        ? await deleteOfficeProduct(props.office.id, productId)
        : await deleteProduct(productId);

    if (result.success) {
      setProvidedProducts((prev) =>
        prev.filter((product) => product.id !== productId)
      );
      showSuccess(notifications.deleteProduct.positive);
    }
  };

  const handleManageModalClose = (
    success: boolean,
    officeIds: Array<string>
  ) => {
    setShowManageModal(false);
    setSelectedProduct(null);
    setSelectedProductOffices([]);

    if (success) {
      const updatedProducts = [...providedProducts];

      const productIndex = updatedProducts.findIndex(
        (product) => product.id === selectedProduct?.id
      );

      if (productIndex !== -1) {
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          assignedOfficeIds: officeIds,
        };

        setProvidedProducts(updatedProducts);

        showSuccess(notifications.saveProduct.positive);
      }
    }
  };

  const createRowData = (
    product: IProduct,
    index: number
  ): ICollapsibleTableRowData => {
    const row: ICollapsibleTableRow = {
      index,
      row: (
        <ProductRow
          product={product}
          hideManageButton={props.providerType !== "lawfirm"}
          handleManage={handleManageClick}
          handleDelete={handleDeleteClick}
        />
      ),
    };

    const collapsibleSection = (
      <CollapsibleTable
        rows={[
          createProductPricingRow(product, index),
          createProductConditionsRow(product, index),
        ]}
        backgroundColor="white"
      />
    );

    return {
      row,
      collapsibleSection,
    };
  };

  const createProductPricingRow = (
    product: IProduct,
    index: number
  ): ICollapsibleTableRowData => {
    const row: ICollapsibleTableRow = {
      index,
      row: <div>EDIT PRICING</div>,
    };

    const collapsibleSection = (
      <ProductFees
        officeId={props.providerType === "office" ? props.office.id : undefined}
        panelId={props.providerType === "office" ? props.panelId : undefined}
        productId={product.id}
      />
    );

    return {
      row,
      collapsibleSection,
    };
  };

  const createProductConditionsRow = (
    product: IProduct,
    index: number
  ): ICollapsibleTableRowData => {
    const row: ICollapsibleTableRow = {
      index,
      row: <div>SET CONDITIONS</div>,
    };

    const collapsibleSection = <ProductConditions productId={product.id} />;

    return {
      row,
      collapsibleSection,
    };
  };

  const rows: ICollapsibleTableRowData[] = providedProducts.map((item, index) =>
    createRowData(item, index)
  );

  const HeaderRow: React.FC = () => {
    return (
      <div className={css.headerRowContainer}>
        <div>PRODUCT</div>
        <div>DATE ADDED</div>
        <div>PRICING UPDATED</div>
        <div></div>
      </div>
    );
  };

  return (
    <>
      {arrayHasItems(providedProducts) ? (
        <CollapsibleTable headerRow={<HeaderRow />} rows={rows} />
      ) : (
        <Alert variant="info">No Products Provided.</Alert>
      )}
      {selectedProduct?.id && (
        <>
          <DeleteProductModal
            productId={selectedProduct.id}
            isOpen={showDeleteModal}
            onClose={handleDeleteModalClose}
            onDelete={handleProductDelete(selectedProduct.id)}
          />
          {props.providerType === "lawfirm" && (
            <ManageProductModal
              open={showManageModal}
              onClose={handleManageModalClose}
              products={props.products.filter(
                (x) => x.id === selectedProduct.id
              )}
              offices={selectedProductOffices}
              existingProductId={selectedProduct.id}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProviderProducts;
