import React, { useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import FloatingLabel from "react-bootstrap-floating-label";
import css from "./index.module.scss";
import { IFeeBand, IFeeBandErrors } from "../types";
import CdnImage from "../../../../../../../components/cdn-image";
import {
  deleteIcon,
  saveIcon,
  unSavedIcon,
} from "../../../../../../../components/cdn-image/img";
import { TempId } from "../../../../../../../types/temp-id";
import { BandId } from "../../../../../types/product-types";
import { usePermissionContext } from "../../../../../../../context/permission-context";

interface FeeBandRowProps {
  feeBand: IFeeBand;
  handleInputChange: (
    id: string,
    field: keyof IFeeBand,
    value: string | number | boolean | undefined
  ) => void;
  handleSave: (feeBand: IFeeBand) => Promise<void>;
  handleDelete: (bandId: BandId | TempId) => Promise<void>;
  isDirty: (feeBand: IFeeBand) => boolean;
  isInError: (errors: IFeeBandErrors) => boolean;
}

const FeeBandRow: React.FC<FeeBandRowProps> = ({
  feeBand,
  handleInputChange,
  handleSave,
  handleDelete,
  isDirty,
  isInError,
}) => {
  const { canEditPricing } = usePermissionContext();

  const [mutating, setMutating] = useState(false);

  const getUpperBandClass = () => {
    if (mutating || !canEditPricing) return css.bandInputMutating;

    return feeBand.errors.invalidUpperBand
      ? css.bandInputError
      : feeBand.dirtyFlags.upperBand
      ? css.bandDirty
      : "";
  };

  const getLowerBandClass = () => {
    if (mutating || !canEditPricing) return css.bandInputMutating;

    return feeBand.errors.lowerBandExceedsUpperBand ||
      feeBand.errors.invalidLowerBand
      ? css.bandInputError
      : feeBand.dirtyFlags.lowerBand
      ? css.bandDirty
      : "";
  };

  const saveBand = async (feeBand: IFeeBand) => {
    setMutating(true);
    try {
      await handleSave(feeBand);
    } finally {
      setMutating(false);
    }
  };

  const deleteBand = async (feeBand: IFeeBand) => {
    setMutating(true);
    try {
      await handleDelete(feeBand.id);
    } finally {
      setMutating(false);
    }
  };

  return (
    <tr className={css.tableRow}>
      <div>
        <FloatingLabel
          label="Lower Band"
          type="number"
          className={getLowerBandClass()}
          onChange={(e) =>
            handleInputChange(
              feeBand.id,
              "lowerBand",
              parseInt((e.target as HTMLInputElement).value)
            )
          }
          initialValue={feeBand.lowerBand?.toString() || ""}
        />
        {feeBand.errors.invalidLowerBand && (
          <div className={css.errorCell}>Enter a positive band value</div>
        )}
        {feeBand.errors.lowerBandExceedsUpperBand && (
          <div className={css.errorCell}>Lower must not exceed upper value</div>
        )}
        {feeBand.dirtyFlags.lowerBand &&
          !feeBand.errors.lowerBandExceedsUpperBand &&
          !feeBand.errors.invalidLowerBand && (
            <div className={css.dirtyCell}>This field has unsaved changes</div>
          )}
      </div>
      <div>
        <FloatingLabel
          label="Upper Band"
          type="number"
          className={getUpperBandClass()}
          onChange={(e) =>
            handleInputChange(
              feeBand.id,
              "upperBand",
              parseInt((e.target as HTMLInputElement).value)
            )
          }
          initialValue={feeBand.upperBand?.toString() || ""}
        />
        {feeBand.errors.invalidUpperBand && (
          <div className={css.errorCell}>Enter a positive band value</div>
        )}
        {feeBand.dirtyFlags.upperBand && !feeBand.errors.invalidUpperBand && (
          <div className={css.dirtyCell}>This field has unsaved changes</div>
        )}
      </div>
      <div>
        <Form.Group>
          <InputGroup className={css.priceElement}>
            <InputGroup.Text>£</InputGroup.Text>
            <Form.Control
              className={css.priceElement}
              type="number"
              placeholder="Net Value"
              value={feeBand.netPrice}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  feeBand.id,
                  "netPrice",
                  parseFloat(e.target.value)
                )
              }
              isInvalid={feeBand.errors.invalidNetPrice}
              disabled={mutating || !canEditPricing}
            />
          </InputGroup>
        </Form.Group>
        {feeBand.errors.invalidNetPrice && (
          <div className={css.errorCell}>Enter a value greater than zero</div>
        )}
      </div>
      <div className={css.internationalContainer}>
        <Form.Check
          className={css.internationalCheck}
          type="checkbox"
          checked={feeBand.hasInternationalNetPrice}
          onChange={(e) =>
            handleInputChange(
              feeBand.id,
              "hasInternationalNetPrice",
              e.target.checked
            )
          }
          disabled={mutating || !canEditPricing}
        />
      </div>
      <div>
        <Form.Group>
          <InputGroup className={css.priceElement}>
            <InputGroup.Text>£</InputGroup.Text>
            <Form.Control
              className={css.priceElement}
              type="number"
              placeholder="International Price"
              value={feeBand.internationalNetPrice}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  feeBand.id,
                  "internationalNetPrice",
                  parseFloat(e.target.value)
                )
              }
              isInvalid={feeBand.errors.invalidInternationalPrice}
              disabled={
                mutating || !feeBand.hasInternationalNetPrice || !canEditPricing
              }
            />
          </InputGroup>
        </Form.Group>
        {feeBand.errors.invalidInternationalPrice && (
          <div className={css.errorCell}>Enter a value greater than zero</div>
        )}
      </div>
      <div>
        <OverlayTrigger
          overlay={
            <Tooltip id={`save-${feeBand.id}`}>
              {isDirty(feeBand) ? "Save Changes" : "Create Fee Band"}
            </Tooltip>
          }
        >
          <span className={css.iconButtonWrapper}>
            <Button
              variant="link"
              onClick={() => saveBand(feeBand)}
              disabled={
                mutating || isInError(feeBand.errors) || !canEditPricing
              }
            >
              <CdnImage
                image={isDirty(feeBand) ? unSavedIcon : saveIcon}
                className={css.iconButton}
              />
            </Button>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id={`delete-${feeBand.id}`}>Delete Fee Band</Tooltip>
          }
        >
          <span className={css.iconButtonWrapper}>
            <Button
              variant="link"
              onClick={() => deleteBand(feeBand)}
              disabled={mutating || !canEditPricing}
            >
              <CdnImage image={deleteIcon} className={css.iconButton} />
            </Button>
          </span>
        </OverlayTrigger>
      </div>
    </tr>
  );
};

export default FeeBandRow;