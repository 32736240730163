import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

interface IDeleteProductModalProps {
  isOpen: boolean;
  onClose: (saved: boolean) => void;
  onDelete: (productId: string) => Promise<void>;
  productId: string;
}

const DeleteProductModal = ({
  isOpen,
  onClose,
  onDelete,
  productId,
}: IDeleteProductModalProps) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsDeleting(true);

    if (productId) {
      await onDelete(productId);
      onClose(true);
    }
    setIsDeleting(false);
  };

  return (
    <Modal show={isOpen} onHide={() => onClose(false)} title="Are you sure?">
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete the product? All associated
          actions/conditions will also be removed.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleSubmit} disabled={isDeleting}>
          {isDeleting ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Deleting...
            </>
          ) : (
            "Remove Product"
          )}
        </Button>
        <Button
          variant="secondary"
          onClick={() => onClose(false)}
          disabled={isDeleting}
        >
          {isDeleting ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Cancelling...
            </>
          ) : (
            "Cancel"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteProductModal;
