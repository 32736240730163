import React from 'react';
import CollapsibleTableRow from "./collapsible-table-row";
import { ICollapsibleTableRowData } from "./types";
import css from "./index.module.scss";

interface ICollapsibleTableProps {
  headerRow?: JSX.Element;
  rows: ICollapsibleTableRowData[];
  backgroundColor?: string;
}

const CollapsibleTable = ({
  headerRow = undefined,
  rows,
  backgroundColor = "#f4f4f4",
}: ICollapsibleTableProps) => {
  return (
    <div className={css.tableContainer} style={{ backgroundColor }}>
      {headerRow && (
        <div className={css.headerContainer}>
          <div className={css.expander}></div>
          <div className={css.header}>{headerRow}</div>
        </div>
      )}

      {rows.map((row) => (
        <CollapsibleTableRow
          row={row.row}
          collapsibleSection={row.collapsibleSection}
        />
      ))}
    </div>
  );
};

export default CollapsibleTable;
