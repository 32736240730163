import React, { useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import css from "./index.module.scss";
import CdnImage from "../../../../../../components/cdn-image";
import {
  saveIcon,
  unSavedIcon,
} from "../../../../../../components/cdn-image/img";
import { resetIcon } from "../../../../../../components/cdn-image/img";
import {
  FeeId,
  OverrideLevel,
} from "../../../../../../types/select-api/ResponseTypes";
import { usePermissionContext } from "../../../../../../context/permission-context";

export type PricingType = OverrideLevel | "Default";

export interface IFeeRow {
  feeId: FeeId;
  name: string;
  category: string;
  pricingType: PricingType;
  netPrice: string;
  netPriceOverride: string | undefined;
  netPriceValidationError: boolean;
  isDirty: boolean;
  disableReset: boolean;
}

interface IFeeRowProps {
  feeRow: IFeeRow;
  handleFeeValueChange: (feeId: string, value: string) => void;
  handleSave: (fee: IFeeRow) => Promise<void>;
  handleFeeReset: (feeId: FeeId) => Promise<void>;
}

const FeeRow: React.FC<IFeeRowProps> = ({
  feeRow,
  handleFeeValueChange,
  handleSave,
  handleFeeReset,
}) => {
  const { canEditPricing } = usePermissionContext();
  const [mutating, setMutating] = useState(false);

  const saveFee = async (feeRow: IFeeRow) => {
    setMutating(true);
    try {
      await handleSave(feeRow);
    } finally {
      setMutating(false);
    }
  };

  const resetFee = async (feeId: FeeId) => {
    setMutating(true);
    try {
      await handleFeeReset(feeId);
    } finally {
      setMutating(false);
    }
  };

  return (
    <div className={css.container}>
      <div className={css.textField}>{feeRow.name}</div>
      <div className={css.textField}>{feeRow.category}</div>
      <div className={css.textField}>{feeRow.pricingType}</div>
      <div>
        <Form.Group controlId={`netPrice-${feeRow.feeId}`}>
          <InputGroup>
            <InputGroup.Text>£</InputGroup.Text>
            <Form.Control
              disabled={!canEditPricing || mutating}
              className={
                feeRow.isDirty && !feeRow.netPriceValidationError
                  ? css.dirtyCell
                  : ""
              }
              type="text"
              placeholder="Net Price"
              value={feeRow.netPriceOverride ?? feeRow.netPrice}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFeeValueChange(feeRow.feeId, e.target.value)
              }
              isInvalid={feeRow.netPriceValidationError}
            />
          </InputGroup>
          {feeRow.netPriceValidationError && (
            <small className={css.errorCell}>Enter a net value greater than zero, to 2 decimal places</small>
          )}
          {feeRow.isDirty && !feeRow.netPriceValidationError && (
            <small className={css.dirtyErrorCell}>This field has unsaved changes</small>
          )}
        </Form.Group>
      </div>
      <div className={css.buttonsContainer}>
        <OverlayTrigger
          overlay={
            <Tooltip id={`save-${feeRow.feeId}`}>
              {feeRow.isDirty ? "Save Changes" : "Save Fee"}
            </Tooltip>
          }
        >
          <span className={css.iconButtonWrapper}>
            <Button
              variant="link"
              aria-label="save"
              onClick={() => saveFee(feeRow)}
              disabled={
                !canEditPricing || mutating || feeRow.netPriceValidationError
              }
            >
              <CdnImage
                image={feeRow.isDirty ? unSavedIcon : saveIcon}
                className={css.iconButton}
              />
            </Button>
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={<Tooltip id={`reset-${feeRow.feeId}`}>Reset Fee</Tooltip>}
        >
          <span className={css.iconButtonWrapper}>
            <Button
              variant="link"
              aria-label="reset"
              onClick={() => resetFee(feeRow.feeId)}
              disabled={
                !canEditPricing ||
                mutating ||
                feeRow.disableReset ||
                feeRow.netPriceValidationError ||
                feeRow.netPrice === feeRow.netPriceOverride
              }
            >
              <CdnImage image={resetIcon} className={css.iconButton} />
            </Button>
          </span>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default FeeRow;
