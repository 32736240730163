import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { resetQuoteData } from "../../redux/actions/actions";
import { slide as Menu } from "react-burger-menu";
import css from "./menu.module.css";
import { ReactComponent as Hamburger } from "../../components/shared/imgs/hamburger.svg";
import { ReactComponent as Close } from "../../components/shared/imgs/close.svg";
import { ReactComponent as IconArrow } from "../../components/shared/imgs/link-closed.svg";
import Button from "../../components/button/button";
import { useHistory } from "react-router-dom";

const Navigation = ({ isBroker, isLawFirm }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const handleMenuItemClick = (location) => {
    setIsOpen(false);
    history.push(location);
  };

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const clearQuoteData = () => {
    dispatch(resetQuoteData());
  };

  const handleNewQuoteClick = () => {
    clearQuoteData();
    handleMenuItemClick("/quote");
  };

  const handleSavedQuoteClick = () => {
    clearQuoteData();
    handleMenuItemClick("/view-quotes");
  };

  const handleViewCasesClick = () => {
    clearQuoteData();
    handleMenuItemClick("/case-progress");
  };

  return (
    <Menu
      id="menu"
      data-testid="menu"
      menuClassName={css.bmMenu}
      burgerButtonClassName={css.bmBurgerButton}
      crossButtonClassName={css.bmCrossButton}
      className={css.outerMenuContainer}
      disableAutoFocus
      customBurgerIcon={<Hamburger className={css.burgerIcon} />}
      customCrossIcon={<Close className={css.crossIcon} />}
      width={"375px"}
      isOpen={isOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      <div className={css.menuContent}>
        <div className={css.spacer}></div>

        <div className={css.menuItem}>
          <Button
            id="menu_home"
            datatestid="home"
            btnClass={css.menuItemText}
            btnTextClass={css.buttonText}
            btnIconClass={css.buttonIcon}
            onClick={() => handleMenuItemClick("/")}
            type="button"
            text="HOME"
            icon={IconArrow}
          />
        </div>

        {isLawFirm && (
          <>
            <div className={css.spacer}></div>
            <div className={css.subHeader}>YOUR FIRM</div>
            <div className={css.menuItem}>
              <Button
                id="menu_mange_offices"
                datatestid="manage_offices"
                btnClass={css.menuItemText}
                btnTextClass={css.buttonText}
                btnIconClass={css.buttonIcon}
                onClick={() => handleMenuItemClick("/manage-offices")}
                type="button"
                text="MANAGE OFFICES"
                icon={IconArrow}
              />
            </div>
          </>
        )}

        {isBroker && (
          <>
            <div className={css.spacer}></div>
            <div className={css.subHeader}>QUOTES</div>
            <div className={css.menuItem}>
              <Button
                id="new_quote"
                datatestid="new_quote"
                btnClass={css.menuItemText}
                btnTextClass={css.buttonText}
                btnIconClass={css.buttonIcon}
                onClick={() => handleNewQuoteClick()}
                type="button"
                text="NEW QUOTE"
                icon={IconArrow}
              />
            </div>

            <div className={css.menuItem}>
              <Button
                id="instruct_quote"
                datatestid="instruct_quote"
                btnClass={css.menuItemText}
                btnTextClass={css.buttonText}
                btnIconClass={css.buttonIcon}
                onClick={() => handleSavedQuoteClick()}
                type="button"
                text="SAVED QUOTES"
                icon={IconArrow}
              />
            </div>

            <div className={css.spacer}></div>
            <div className={css.subHeader}>CASES</div>
            <div className={css.menuItem}>
              <Button
                id="check_case"
                datatestid="check_case"
                btnClass={css.menuItemText}
                btnTextClass={css.buttonText}
                btnIconClass={css.buttonIcon}
                onClick={() => handleViewCasesClick()}
                type="button"
                text="CASE PROGRESS"
                icon={IconArrow}
              />
            </div>
          </>
        )}

        {isLawFirm && (
          <>
            <div className={css.spacer}></div>
            <div className={css.subHeader}>PRICING</div>
            <div className={css.menuItem}>
              <Button
                id="menu_manage_products_pricing"
                datatestid="manage_products_pricing"
                btnClass={css.menuItemText}
                btnTextClass={css.buttonText}
                btnIconClass={css.buttonIcon}
                onClick={() => handleMenuItemClick("/manage-products")}
                type="button"
                text="MANAGE PRODUCTS & PRICING"
                icon={IconArrow}
              />
            </div>
          </>
        )}

        {(isLawFirm) && (
          <>
            <div className={css.spacer}></div>
            <div className={css.menuItem}>
              <Button
                id="menu_mange_panels"
                datatestid="manage_panels"
                btnClass={css.menuItemText}
                btnTextClass={css.buttonText}
                btnIconClass={css.buttonIcon}
                onClick={() => handleMenuItemClick("/manage-panels")}
                type="button"
                text="PANELS"
                icon={IconArrow}
              />
            </div>
          </>
        )}

        {isBroker && (
          <>
            <div className={css.spacer}></div>
            <div className={css.menuItem}>
              <Button
                id="preferences"
                datatestid="preferences"
                btnClass={css.menuItemText}
                btnTextClass={css.buttonText}
                btnIconClass={css.buttonIcon}
                onClick={() => handleMenuItemClick("/preferences")}
                type="button"
                text="PREFERENCES"
                icon={IconArrow}
              />
            </div>
          </>
        )}
      </div>
    </Menu>
  );
};

export default Navigation;
