import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import css from "./index.module.scss";

import AuthCheck from "../../../components/authcheck/authCheck";
import Layout from "../../../layout";
import useSelectApi from "../../../hooks/useSelectApi";
import {
  IOffice,
  IProduct,
  PanelId,
  ProviderId,
} from "../../../types/select-api/ResponseTypes";
import Loading from "../../../components/loading";
import Breadcrumb from "../components/breadcrumbs";
import ProviderProducts from "../components/products";
import OfficePanelSelector from "../components/office-panel-selector";
import EditPricingPermissionBanner from "../components/edit-pricing-permission-banner";

interface IOfficePricingProps {
  officeId: ProviderId;
}

const OfficePricing = ({ officeId }: IOfficePricingProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [office, setOffice] = useState<IOffice>();
  const [products, setProducts] = useState<Array<IProduct>>([]);
  const [selectedPanelId, setSelectedPanelId] = useState<PanelId | undefined>(
    undefined
  );

  const breadcrumbs = [
    { name: "Home", path: "/" },
    { name: "Offices", path: "/manage-offices" },
    { name: office?.name ?? "", isActive: true },
    { name: "Pricing", isActive: true },
  ];

  const { getOffice, getProducts } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: false,
  });

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const productsResult = await getProducts();
      if (productsResult.success) {
        const officeProducts = productsResult.data.filter((p) =>
          p.assignedOfficeIds.some((id) => id === officeId)
        );
        setProducts(officeProducts);
      }

      const officeResult = await getOffice(officeId);
      if (officeResult.success) {
        setOffice(officeResult.data);
      }
      setIsLoading(false);
    };

    loadData();
  }, [officeId]);

  return (
    <Layout>
      <AuthCheck requiredAuthState="AUTHENTICATED" />
      {isLoading && <Loading />}
      {!isLoading && (
        <Row className={css.bottompad2x}>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <Row>
              <Col md={12} xs={12}>
                <Breadcrumb items={breadcrumbs} />
              </Col>
            </Row>
            <Row>
              <Col md={10} xs={10}>
                <div className={css.header}>YOUR OFFICE'S PRODUCTS</div>
              </Col>
            </Row>
            <Row>
              <Col xs={10} className={css.instructions}>
                You can choose to keep this office's pricing the same as the
                firm's default, or you can customise your pricing for this
                office only. To customise pricing for a specific panel please
                select that panel's name.
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <OfficePanelSelector
                  officeId={officeId!}
                  selectedPanelId={selectedPanelId}
                  setSelectedPanelId={setSelectedPanelId}
                />
              </Col>
            </Row>
            <EditPricingPermissionBanner />

            <Row className={css.tableContainer}>
              <Col md={12} xs={12}>
                <ProviderProducts
                  providerType="office"
                  products={products}
                  office={office!}
                  panelId={selectedPanelId}
                />
              </Col>
            </Row>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
      )}
    </Layout>
  );
};

export default OfficePricing;
