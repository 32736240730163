import css from "./index.module.scss";
import { Form } from "react-bootstrap";
import {
  PanelId,
  ProviderId,
} from "../../../../types/select-api/ResponseTypes";
import useOfficePanels from "./useOfficePanels";

type PanelSelectorProps = {
  officeId: ProviderId;
  selectedPanelId: PanelId | undefined;
  setSelectedPanelId: React.Dispatch<React.SetStateAction<PanelId | undefined>>;
};

const OfficePanelSelector = ({
  officeId,
  selectedPanelId,
  setSelectedPanelId,
}: PanelSelectorProps) => {
  const { loading: panelsLoading, panels } = useOfficePanels(officeId);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPanelId = evt.target.value;
    const selectedPanel = panels.find((panel) => panel.id === selectedPanelId);
    setSelectedPanelId(selectedPanel ? selectedPanel.id : undefined);
  };

  return (
    <Form>
      <Form.Group className={css.label}>
        <Form.Label id="state" htmlFor="panelSelector">
          Choose panel
        </Form.Label>
        <Form.Control
          id="panelSelector"
          as="select"
          value={selectedPanelId ?? "(none)"}
          onChange={handleChange}
          disabled={panelsLoading}
          custom
        >
          <option value="(none)">(Default Pricing)</option>
          {panels.map((panel) => (
            <option key={panel.id} value={panel.id}>
              {panel.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </Form>
  );
};

export default OfficePanelSelector;
