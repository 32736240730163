import React, { useState, useEffect } from "react";
import { arrayHasItems } from "@lms/react-common-helpers/arrays";
import css from "./index.module.scss";
import { Button, Form, ListGroup, Modal, Spinner } from "react-bootstrap";
import { IProduct, IOfficeProvider } from "./types";
import useSelectApi from "../../../../../hooks/useSelectApi";
import {
  ProductId,
  ProviderId,
} from "../../../../../types/select-api/ResponseTypes";

interface IManageLawFirmProductsModalProps {
  open: boolean;
  onClose: (success: boolean, officeIds: Array<string>) => void;
  products: IProduct[]; //products to choose from
  offices: IOfficeProvider[]; //the law firms offices
  existingProductId?: string; //the product being edited
}

const ManageLawFirmProductsModal = ({
  open,
  onClose,
  products,
  offices,
  existingProductId,
}: IManageLawFirmProductsModalProps) => {
  const { deleteOfficeProduct, saveProduct } = useSelectApi({
    suppressErrorRedirect: true,
    suppressErrorMessages: true,
  });

  const [existingOfficeProviders] = useState<ProviderId[]>(
    offices.filter((x) => x.hasProductAssigned).map((p) => p.id)
  );
  const [selectedOfficeIds, setSelectedOfficeIds] = useState<ProviderId[]>(
    offices.filter((x) => x.hasProductAssigned).map((p) => p.id)
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [toDeleteIds, setToDeleteIds] = useState<ProviderId[]>([]);
  const [validationProductError, setValidationProductError] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<
    ProductId | undefined
  >();
  const isEditing = Boolean(existingProductId);

  useEffect(() => {
    if (arrayHasItems(products)) {
      setSelectedProduct(isEditing ? products[0].id! : undefined);
    }
  }, [products, isEditing]);

  const saveProductAsync = async (
    productId: ProductId,
    officeIds: Array<string>
  ) => {
    const response = await saveProduct(productId, {
      officeIds,
    });
    return response.success;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    setIsSaving(true);
    await saveProductAsync(selectedProduct!, selectedOfficeIds);

    for (const idToDelete of toDeleteIds) {
      await deleteOfficeProduct(idToDelete, selectedProduct!);
    }
    setIsSaving(false);
    onClose(true, selectedOfficeIds);
  };

  const handleOfficeToggled = (office: IOfficeProvider) => () => {
    const currentIndex = selectedOfficeIds.indexOf(office.id);
    const newChecked = [...selectedOfficeIds];
    const newToDeleteIds = [...toDeleteIds];

    if (currentIndex === -1) {
      newChecked.push(office.id);

      // Remove from toDeleteIds if it was marked for deletion but now reselected
      const matchedOffice = existingOfficeProviders.find(
        (o) => o === office.id
      );
      if (matchedOffice && newToDeleteIds.includes(matchedOffice)) {
        newToDeleteIds.splice(newToDeleteIds.indexOf(matchedOffice), 1);
      }
    } else {
      newChecked.splice(currentIndex, 1);

      // If it was in existingOfficeProviders, add to toDeleteIds
      const matchedProvider = existingOfficeProviders.find(
        (o) => o === office.id
      );
      if (matchedProvider) {
        newToDeleteIds.push(matchedProvider);
      }
    }
    setSelectedOfficeIds(newChecked);
    setToDeleteIds(newToDeleteIds);
  };

  const validate = () => {
    if (!selectedProduct) {
      setValidationProductError(true);
      return false;
    } else {
      setValidationProductError(false);
      return true;
    }
  };

  return (
    <Modal
      show={open}
      onHide={() => onClose(false, selectedOfficeIds)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? "Edit Product" : "Add Product"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={css.modalBody}>
        <Form>
          <Form.Group controlId="product">
            {!isEditing && <Form.Label>Select a product</Form.Label>}
            <Form.Control
              as="select"
              value={selectedProduct}
              onChange={(e) => {
                const selectedProductId = e.target.value;
                const selectedProduct = products.find(
                  (product) => product.id === selectedProductId
                );
                setSelectedProduct(
                  selectedProduct ? selectedProduct.id! : undefined
                );
              }}
              disabled={isEditing}
              isInvalid={validationProductError}
            >
              <option value="">Choose a product</option>
              {products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a product.
            </Form.Control.Feedback>
          </Form.Group>
          <p>Which office(s) will provide this product?</p>
          <ListGroup>
            {offices.map((office) => {
              const officeId = office.id;
              return (
                <ListGroup.Item key={officeId}>
                  <Form.Check
                    type="checkbox"
                    id={`office-checkbox-${officeId}`}
                    label={office.name}
                    checked={selectedOfficeIds.includes(officeId)}
                    onChange={handleOfficeToggled(office)}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          {toDeleteIds.length > 0 && (
            <p className="text-danger">
              Note: This will remove any deselected offices and all associated
              pricing.
            </p>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className={css.modalFooter}>
        <Button variant="primary" onClick={handleSubmit} disabled={isSaving}>
          {isSaving ? <Spinner animation="border" size="sm" /> : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageLawFirmProductsModal;
