import React from "react";
import { useConfiguration } from "../../context/configuration-context";
import { IImage } from "./img";

interface ICdnImageProps {
  image: IImage;
  className?: string;
}

const CdnImage = ({ className, image }: ICdnImageProps) => {
  const { cdnBaseAddress } = useConfiguration();

  return (
    <img
      className={className}
      src={`${cdnBaseAddress}/${image.path}`}
      alt={image.alt}
    />
  );
};

export default CdnImage;
 