import css from "./index.module.scss";

import { Alert, Col, Row } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import { usePermissionContext } from "../../../../context/permission-context";

const EditPricingPermissionBanner = () => {
  const { canEditPricing } = usePermissionContext();

  if (canEditPricing) return null;

  return (
    <Row>
      <Col md={12}>
        <Alert
          key="warning"
          variant="warning"
          className={css.permissionWarning}
        >
          <ExclamationCircle />
          &nbsp; You don't have permission to change these settings. If you wish
          to make changes, please ask a Servicer Admin at your organisation.
        </Alert>
      </Col>
    </Row>
  );
};

export default EditPricingPermissionBanner;
