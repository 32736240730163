import React from 'react';
import { Button, Collapse } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { useState } from "react";
import { ICollapsibleTableRow } from "../types";
import css from "./index.module.scss";

interface ICollapsibleTableRowProps {
  row: ICollapsibleTableRow;
  collapsibleSection: JSX.Element;
}

const CollapsibleTableRow = ({
  row,
  collapsibleSection,
}: ICollapsibleTableRowProps) => {
  const [openSection, setOpenSections] = useState<boolean>(false);

  const handleSectionToggle = () => {
    setOpenSections((prev) => !prev);
  };

  return (
    <>
      <div className={css.rowContainer}>
        <div className={css.expander}>
          <Button
            aria-label={openSection ? `collapse row ${row.index}` : `expand row ${row.index}`}
            size="sm"
            variant="link"
            onClick={handleSectionToggle}
          >
            {openSection ? <ChevronUp /> : <ChevronDown />}
          </Button>
        </div>
        <div className={css.row}>{row.row}</div>
      </div>

      <Collapse in={openSection} unmountOnExit>
        <div className={css.rowContainer}>
          <div className={css.expander} />
          <div className={css.row}>{collapsibleSection}</div>
        </div>
      </Collapse>
    </>
  );
};

export default CollapsibleTableRow;
